import React, { useState, useCallback } from 'react';
import styles from './ImageS3Upload.module.scss';
import Button from '../Button/Button';
import classNames from 'classnames';
import { useDropzone } from 'react-dropzone';

interface Props {
    ImageSetupTemplate?: {
        extension:string;
        image_base_path:string;
        resize:string;
        s3_base_path:string;
    };
}

export function ImageS3Upload({
                                  ImageSetupTemplate
                              }: Props): React.ReactElement {
    const [openModalState, setOpenModalState] = useState(false);
    const [file, setFile] = useState<File | null>(null);
    const [preview, setPreview] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles.length < 1) {
            return;
        }

        const currentFile = acceptedFiles[0];

        // Image resolution check
        const img = new Image();
        img.onload = () => {
            const { width, height } = img;
            const [requiredWidth, requiredHeight] = ImageSetupTemplate?.resize.split('x').map(Number) || [];
            const aspectRatio = width / height;
            const requiredAspectRatio = requiredWidth / requiredHeight;
            const tolerance = 0.05; // 5% tolerance

            setFile(currentFile);
            setPreview(URL.createObjectURL(currentFile));

            if (Math.abs(aspectRatio - requiredAspectRatio) > requiredAspectRatio * tolerance) {
                setErrorMessage(`The aspect ratio of the image should be approximately ${requiredAspectRatio.toFixed(2)}. The recommended resolution is ${requiredWidth}x${requiredHeight}.`);
            } else {
                setErrorMessage(null);
            }
        };
        img.src = URL.createObjectURL(currentFile);
    }, [ImageSetupTemplate]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: false,
        accept: ['.jpg', '.png', '.jpeg', '.gif']
    });

    const openModal = () => {
        setOpenModalState(true);
    };

    const closeModal = () => {
        setOpenModalState(false);
    };

    return (
        <div className={styles.root}>
            <div>
                <Button
                    size="large"
                    type="action"
                    onClick={openModal}
                    className={styles.submitButton}
                >
                    Upload Image
                </Button>
                {openModalState && (
                    <div className={styles.modalWrapper}>
                        <div className={styles.modalBody}>
                            <div className={styles.modalContent}>
                                <div
                                    {...getRootProps({
                                        onClick: (event) => {
                                            const button = (event.target as HTMLElement).closest('#chooseFileBtn');
                                            if (!button) {
                                                event.stopPropagation();
                                            }
                                        },
                                    })}
                                    className={classNames(styles.dndArea, { [styles.dragActive]: isDragActive })}
                                >
                                    <h2 className={styles.heading}>{file === null ? 'Drag and drop your JPG or PNG file' : `File: ${file?.name}`}</h2>
                                    {preview && <img src={preview} alt="Preview" className={styles.previewImage} />}
                                    <p className={styles.text}>
                                        Alternatively click the button below to browse
                                        <br />
                                        your local files.
                                    </p>
                                    <Button type="action" size="large" className={styles.chooseFileButton} id="chooseFileBtn">
                                        {file === null ? 'Choose file' : 'Choose another file'}
                                    </Button>
                                    <input {...getInputProps()} />
                                </div>

                                {file && errorMessage && (
                                    <p className={styles.errorMessage}>{errorMessage}</p>
                                )}
                            </div>
                            <div className={styles.modalAction}>
                                <Button disabled={!!(file && errorMessage)} type="action">
                                    Upload
                                </Button>
                                <Button onClick={closeModal}>
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ImageS3Upload;
