import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import find from 'lodash/find';
import toNumber from 'lodash/toNumber';
import { RootState } from 'app/store';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import { showNotification } from 'state/ui/notifications/notificationsSlice';
import { setCampaignRewards, setIsRewardCreated, setRewardsVoucherCodesFieldValue } from '../../../../state/campaign/campaignSlice';
import { generateCodes } from '../../../../api/campaignApi';
import styles from './PartnerPortal.module.scss';

export default function PartnerPortal(): React.ReactElement {
  const dispatch = useDispatch();
  const history = useHistory();

  const [notVisibleInEmail, setNotVisibleInEmail] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [busy, setBusy] = useState(false);
  const [quantity, setQuantity] = useState('');

  const rewards = useSelector<RootState, any | null>((state) => state.campaign.campaign.rewards);
  const isRewardCreated = useSelector<RootState, boolean>((state) => state.campaign.isRewardCreated);
  const rewardId = useSelector<RootState, string | null>((state) => state.campaign.rewardId);
  const campaign = useSelector<RootState, any | null>((state) => state.campaign);
  const rewardsValues = useSelector<RootState,
    { reward_name: string }>((state) => state.campaign.rewardsSettings.values);
  const voucherCodesvalues = useSelector<RootState, { [key: string]: number | string[] | null | boolean }>(
    (state) => state.campaign.rewardVoucherCodes.values
  );

  const reward = find(campaign && campaign.campaign && campaign.campaign.rewards, (r: any) => {
    return r.reward_id === campaign.rewardId;
  }) || {};
  const partner = find(campaign && campaign.campaign && campaign.campaign.partners, (p: any) => {
    return p.partner_id === reward.partner_id;
  }) || {};

  const handleChange = (value: string) => {
    setErrorMessage('');

    if (!!value && toNumber(value) < 1) {
      setErrorMessage('Number of codes should be greater than 0.');
      return;
    }

    if (!!value && toNumber(value) > 1000) {
      setErrorMessage('Number of codes should not be greater than 1000.');
      return;
    }

    setQuantity(value);
    dispatch(setRewardsVoucherCodesFieldValue({
      field: 'win_max',
      value: +value
    }))
  }

  const generateCodesFunction = () => {
    const params = {
      campaign_id: reward.campaign_id || '',
      organisation_id: campaign.campaign.licensor_organisation_id || '',
      reward_id: reward.reward_id || '',
      reward_name: reward.reward_name || '',
      reward_title: reward.reward_attributes.en.reward_title || '',
      partner_id: reward.partner_id || '',
      partner_name: partner.name || '',
      quantity: quantity || '',
      not_visible_in_email: notVisibleInEmail || false
    }

    setBusy(true);

    generateCodes(params).then(() => {
      const newRewards = rewards?.map((item: any) =>
        item.reward_id === rewardId
          ? { ...item, win_max: voucherCodesvalues.win_max }
          : item);
      dispatch(setCampaignRewards(newRewards));
      dispatch(showNotification(`${quantity} codes were generated successfully!`));
      setErrorMessage('');
      setBusy(false);
    }, () => {
      setErrorMessage('Oops! Something went wrong. Please try again.')
      setBusy(false);
    })
  }


  useEffect(() => {
    if (isRewardCreated) {
      dispatch(setIsRewardCreated(false));
      const pathArray = location.pathname.split('/');
      if (rewardId) {
        if (pathArray[pathArray.length - 3] === 'edit') {
          const pathArray = location.pathname.split('/');
          pathArray[pathArray.length - 2] = rewardId;
          history.push(pathArray.join('/'));
        } else {
          history.push(`./edit/${rewardId}/editRewards`);
        }
      }
    }
  })

  return (
    <form
      className={styles.root}
      onSubmit={(e) => {
        generateCodesFunction();
        e.preventDefault();
      }}
    >
      <div className={styles.formInputContainer}>
        <label className={styles.inputLabel}>
          Reward Name
        </label>
        <div className={styles.textInput}>
          {rewardsValues.reward_name}
        </div>
      </div>

      <div className={styles.form}>
        <div className={styles.inputHolderGrow}>
          <p className={styles.label}>Number of codes</p>
          <Input
            type="text"
            required
            value={quantity}
            onChange={(e) => handleChange(e.target.value.replace(/[^0-9]/g, ''))}
            className={styles.bigInput}
          />
        </div>

        <div className={styles.checkboxInput}>
          <label>
            <input type="checkbox"
              defaultChecked={notVisibleInEmail}
              onChange={(e) => {
                setNotVisibleInEmail(e.target.checked)
              }}
            />
            <p className={styles.label}>Not visible in email</p>
          </label>
        </div>
      </div>

      <Button submit
        size="large"
        type="action"
        className={styles.submit}
        disabled={!!busy}
      >
        {!!busy && (<div className={styles.loader}></div>)}
        Generate codes
      </Button>

      {!!errorMessage && (
        <p className={styles.errorMessage}>{errorMessage}</p>
      )}
    </form>
  );
}
