import apiClient from '../app/apiClient/apiClient';

export const fetchCampaigns = (): any => {
  return apiClient.get('/v1/redemption-delivery/manage/campaigns').then((data) => data);
};

export const patchCampaign = ({
  campaign_id,
  campaign_name,
  campaign_image_url,
  properties
}: {
  campaign_id: string;
  campaign_name: string;
  campaign_image_url: string;
  properties: {key: string}
}): any => {
  return apiClient
    .patch(`/v1/redemption-delivery/manage/campaigns/${campaign_id}`, {
      campaign_name,
      campaign_image_url,
      properties
    })
    .then((data) => data);
};
