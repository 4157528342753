import toNumber from 'lodash/toNumber';
import config from '../../env-config';
import cookies from 'js-cookie';
import {
    USER_REFRESH_TOKEN_COOKIE,
    USER_TOKEN_COOKIE,
    ORG_ID_COOKIE,
    ORG_LIST_COOKIE,
} from 'app/constants/oauthConstants';
import { parseISO } from 'date-fns';
import { REWARD_STATUSES } from 'app/constants/rewardConstants';

export const serializeObject = (obj: { [key: string]: any }): string => {
    const str = Object.entries(obj).reduce(
        (acc: any, [k, v]: [string, any]): any[] => [...acc, encodeURIComponent(k) + '=' + encodeURIComponent(v)],
        []
    );
    return str.join('&');
};

export const redirectToLogin = (): void => {
    const source = config.services.reach.split('://')[1];
    const path = `${config.services.login}?source=${source}`;
    window.location.replace(path);
};

export const logout = (): void => {
    clearUserCookies();
    redirectToLogin();
};

export const clearUserCookies = (): void => {
    cookies.remove(USER_REFRESH_TOKEN_COOKIE, {
        domain: config.appDomain,
        secure: true,
        sameSite: 'strict',
    });
    cookies.remove(ORG_LIST_COOKIE, {
        domain: config.appDomain,
        secure: true,
        sameSite: 'strict',
    });
    cookies.remove(USER_TOKEN_COOKIE, {
        domain: config.appDomain,
        secure: true,
        sameSite: 'strict',
    });
};

export const clearOrganisationCookies = (): void => {
    cookies.remove(ORG_ID_COOKIE, {
        domain: config.appDomain,
        secure: true,
        sameSite: 'strict',
    });
};

export const replaceEmptyValuesWithNulls = (obj: { [key: string]: any }): any =>
    Object.entries(obj).reduce(
        (acc, [k, v]): any => (v === '' || (v && v.length === 0) ? { ...acc, [k]: null } : { ...acc, [k]: v }),
        {}
    );

export const parseVoucherCodesFromCsv = async (file: File): Promise<string[]> => {
    const fileContents = await file.text();
    return fileContents
        .split('\n')
        .map((val) => val.trim())
        .filter((v) => !!v);
};

export const trimAllValues = (obj: { [key: string]: any }, type?: string): any => {
    const trimValues = type === 'reward_languages'
        ? Object.assign({},
            ...Object.entries(obj).map(([key, val]) => {
                return {
                    [key]: Object.keys(val).reduce((prev, next) =>
                        Object.assign(prev, {
                            [next]: !!val[next]?.length && typeof val[next] === 'string'
                                ? val[next].trim()
                                : val[next]
                        }), {})

                }
            }))
        : (Object.keys(obj).reduce((prev, next) =>
            Object.assign(prev, {
                [next]: !!obj[next]?.length && typeof obj[next] === 'string'
                    ? obj[next].trim()
                    : Array.isArray(obj[next])
                        ? obj[next].map((item: any) => item.trim())
                        : obj[next]
            }), {})
        )
    return trimValues;
}

export const getRewardStatus = (startDateStr: string, endDateStr: string, dailyLimit: string | number | null, winCountToday: string | number | null, is_enabled: boolean, winMax: string | number, winCount: string | number): string => {
    const start = startDateStr ? parseISO(startDateStr) : new Date(0);
    const end = endDateStr ? parseISO(endDateStr) : parseISO('3000-01-01');
    const now = new Date();

    dailyLimit = dailyLimit && dailyLimit !== null ? toNumber(dailyLimit) : null;
    winCountToday = winCountToday ? toNumber(winCountToday) : 0;
    let status = REWARD_STATUSES.ACTIVE;

    if (!is_enabled) {
        status = REWARD_STATUSES.DISABLED
    } else if ((+winMax - +winCount) < 1) {
        status = REWARD_STATUSES.EMPTY;
    } else if (end < now) {
        status = REWARD_STATUSES.EXPIRED;
    } else if (start > now) {
        status = REWARD_STATUSES.PENDING;
    } else if (dailyLimit !== null && (winCountToday >= dailyLimit)) {
        status = REWARD_STATUSES.PAUSED;
    }
    return status;
};

export const doesValuePassFilter = (field: string | number, filterValue: string | number | string[]): boolean => {
    if (typeof filterValue === 'string') {
        if (typeof field === 'string') {
            return field?.toLowerCase()?.includes(filterValue.toLowerCase());
        } else {
            return field?.toString()?.toLowerCase()?.includes(filterValue.toLowerCase());
        }
    } else {
        return field === filterValue;
    }
};
