import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import { cloneDeep } from 'lodash';
import { RootState } from 'app/store';
import { parseVoucherCodesFromCsv } from 'app/utils/helpers';
import Button from 'components/Button/Button';
import Input from 'components/Input/Input';
import {
    sendCodesData,
    setIsRewardCreated,
    setRewardsVoucherCodesFieldValue
} from '../../../../state/campaign/campaignSlice';
import styles from './VoucherCodesDropzone.module.scss';

export default function VoucherCodesDropzone(): React.ReactElement {
    const dispatch = useDispatch();

    const values = useSelector<RootState, { [key: string]: number | string[] | boolean | null }>(
        (state) => state.campaign.rewardVoucherCodes.values
    );
    const rewardsValues = useSelector<RootState,
        { reward_name: string }>((state) => state.campaign.rewardsSettings.values);
    const [file, setFile] = useState<File | null>(null);
    const isRewardCreated = useSelector<RootState, boolean>((state) => state.campaign.isRewardCreated);
    const rewardId = useSelector<RootState, string | null>((state) => state.campaign.rewardId);
    const history = useHistory();
    const [preservedData, setPreservedData] = useState(values);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (Array.isArray(values.codes) && values.codes?.length > 1000) {
            setErrorMessage('The uploaded file exceeds 1000 lines')
        } else {
            setErrorMessage('')
        }
    }, [values.codes])

    useEffect(() => {
        if (isRewardCreated) {
            dispatch(setIsRewardCreated(false));
            const pathArray = location.pathname.split('/');

            if (rewardId) {
                if (pathArray[pathArray.length - 3] === 'edit') {
                    const pathArray = location.pathname.split('/');
                    pathArray[pathArray.length - 2] = rewardId;
                    history.push(pathArray.join('/'));
                } else {
                    history.push(`./edit/${rewardId}/editRewards`);
                }
            }
        }
    })

    const onDrop = useCallback(async (acceptedFiles) => {
        if (acceptedFiles.length < 1) {
            return;
        }
        const currentFile = acceptedFiles[0];
        setFile(currentFile);
        const codes = await parseVoucherCodesFromCsv(currentFile);
        dispatch(setRewardsVoucherCodesFieldValue({ field: 'codes', value: codes }));
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: false, accept: '.csv' });

    const preserveCampaign = (key: string, value: string[] | number | null | boolean) => {
        const newData = cloneDeep(preservedData);
        newData[key] = value;
        setPreservedData(newData);
    }

    return (
        <form
            className={styles.root}
            onSubmit={(e) => {
                dispatch(sendCodesData({ fileUploadCodes: true }));
                e.preventDefault();
            }}
        >
            <div className={styles.formInputContainer}>
                <label className={styles.inputLabel}>
                    Reward Name
                </label>
                <div className={styles.textInput}>
                    {rewardsValues.reward_name}
                </div>
            </div>

            <div
                {...getRootProps({
                    onClick: (event) => {
                        const button = (event.target as HTMLElement).closest('#chooseFileBtn');
                        if (!button) {
                            event.stopPropagation();
                        }
                    },
                })}
                className={classNames(styles.dndArea, { [styles.dragActive]: isDragActive })}
            >
                <h2 className={styles.heading}>{file === null ? 'Drag and drop your CSV file' : `File: ${file?.name}`}</h2>
                <p className={styles.text}>
                    Alternatively click the button below to browse
                    <br />
                    your local files.
                </p>
                <div className={styles.inline}>
                    <Button
                        type="action"
                        size="large"
                        className={styles.chooseFileButton}
                        id="chooseFileBtn"
                    >
                        {file === null ? 'Choose file' : 'Choose another file'}
                    </Button>

                    {file !== null && (
                        <Button
                            type="danger"
                            size="large"
                            className={styles.chooseFileButton}
                            id="removeFileBtn"
                            onClick={()=>setFile(null)}
                        >
                            Remove file
                        </Button>
                    )}
                </div>
                <input {...getInputProps()} />
            </div>

            <div className={styles.inputHolder}>
                <p className={styles.label}>Times voucher codes can be used</p>
                <Input
                    value={values.win_max as number}
                    onChange={(e) => dispatch(setRewardsVoucherCodesFieldValue({
                        field: 'win_max',
                        value: +e.target.value
                    }))}
                    type="number"
                    min={1}
                    max={1000000000}
                    className={styles.smallInput}
                />
            </div>

            <div className={styles.checkboxInput}>
                <label>
                    <input type="checkbox"
                        defaultChecked={!!values.not_visible_in_email_upload}
                        onChange={(e) => {
                            dispatch(setRewardsVoucherCodesFieldValue({
                                field: 'not_visible_in_email_upload',
                                value: e.target.checked
                            }))
                            preserveCampaign('not_visible_in_email_upload', e.target.checked);
                        }}
                    />
                    <p className={styles.label}>Not visible in email</p>
                </label>
            </div>
            {errorMessage && <div className={styles.red}>{errorMessage}</div>}
            <Button
                disabled={file === null || !!errorMessage}
                submit
                size="large"
                type="action"
                className={styles.submit}
            >
                Add codes
            </Button>
        </form>
    );
}
