import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { RootState } from 'app/store';
import { editCampaign, setError } from '../../../../state/campaigns/CampaignsSlice';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import ImageUpload from 'components/ImageUpload/ImageUpload';
import Dropdown from 'components/Dropdown/Dropdown';
import { DEFAULT_TIMEZONE, OFFSETS } from 'constants/rewardConstants';
import styles from './EditCampaign.module.scss';

export default function EditCampaign(): React.ReactElement {
  const dispatch = useDispatch();

  const campaign = useSelector<RootState, any>((state) => state.campaign.campaign);
  const error = useSelector<RootState, any>((state) => state.campaigns.error);

  const selectedTimezone = campaign?.properties?.view_timezone ? campaign?.properties?.view_timezone : DEFAULT_TIMEZONE;
  const [startZone, setStartZone] = useState('');

  useEffect(() => {
    setStartZone(selectedTimezone);
  }, [campaign.properties.view_timezone])

  return (
    <Formik
      enableReinitialize={true}
      initialValues={campaign}
      validate={(values) => {
        const errors: any = {};
        if (!values.campaign_name) {
          errors.campaign_name = 'Please, specify campaign name';
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        const newProperties = { ...values.properties, view_timezone: startZone }
        dispatch(editCampaign({ ...values, properties: newProperties }));
        setSubmitting(true);
      }}
    >
      {({ values, setFieldValue, setTouched, errors, dirty, handleChange, handleSubmit, touched }) => (
        <form className={styles.root} onSubmit={handleSubmit}>
          <div className={styles.headerSection}>
            <ImageUpload
              imageUrl={values?.campaign_image_url}
              onImageSubmit={(e) => {
                setTouched({ ...touched, campaign_image_url: true }, true);
                setFieldValue('campaign_image_url', e);
                dispatch(setError(''));
              }}
              error={touched.campaign_image_url ? errors.campaign_image_url || error : null}
            />
          </div>
          <div className={styles.formInputContainer}>
            <label className={styles.inputLabel}>Campaign Name</label>
            <Input
              className={styles.formInput}
              value={values?.campaign_name}
              name="campaign_name"
              onChange={handleChange}
              error={touched.campaign_name ? (errors.campaign_name as string) : null}
            />
          </div>

          <div className={styles.formInputContainer}>
            <label className={styles.inputLabel}>View Timezone</label>
            <Dropdown
              options={OFFSETS.map((offset) => ({ value: offset, label: 'UTC' + offset }))}
              className={styles.formInput}
              value={{
                label: 'UTC' + startZone,
                value: startZone
              }}
              name="startOffset"
              onChange={({ value }) => setStartZone(value)}
            />
          </div>

          <Button submit disabled={!dirty && startZone === selectedTimezone} type="action" size="large" className={styles.submitButton}>
            Confirm Changes
          </Button>
        </form>
      )}
    </Formik>
  );
}
