import {createSlice} from '@reduxjs/toolkit';
import {Filter} from 'app/components/Table/tableInterfaces';

interface CampaignDetailsSlice {
    partnerTableSort: { direction: string; field: string };
    rewardsTableSort: { direction: string; field: string };
    rewardsTableFilters: { [key: string]: Filter };
    rewardsTablePage: number;
    rewardsRowsPerPage: number;
    metricsTableSort: { direction: string; field: string };
    metricsTableFilters: { [key: string]: Filter };
    metricsTablePage: number;
    metricsRowsPerPage: number;
    shortcodesTableSort: {direction: string; field: string};
    shortcodesTableFilters: { [key: string]: Filter };
    shortcodesTablePage: number;
    shortcodesRowsPerPage: number;
}

const initialState = {
    partnerTableSort: {direction: '', field: ''},
    rewardsTableSort: {direction: '', field: ''},
    rewardsTablePage: 0,
    rewardsRowsPerPage: 20,
    metricsRowsPerPage: 20,
    metricsTableSort: {direction: 'DESC', field: 'created_at'},
    metricsTablePage: 0,
    metricsTableFilters: {},
    rewardsTableFilters: {},
    shortcodesTablePage: 0,
    shortcodesRowsPerPage: 20,
    shortcodesTableSort: {direction: 'DESC', field: 'created_at'},
    shortcodesTableFilters: {}
} as CampaignDetailsSlice;

export const authSlice = createSlice({
    name: 'campaignsDetails',
    initialState,
    reducers: {
        setPartnerTableSort: (state, action) => {
            const field = action.payload;
            const {field: oldField, direction: oldDirection} = state.partnerTableSort;
            let direction = 'DESC';
            if (oldField === field) {
                direction = oldDirection === 'DESC' ? 'ASC' : 'DESC';
            }
            state.partnerTableSort = {direction, field};
        },
        resetPartnerTableSort: (state) => {
            state.partnerTableSort = {direction: '', field: ''};
        },
        setRewardsRowsPerPage: (state, action) => {
            state.rewardsRowsPerPage = action.payload;
            state.rewardsTablePage = 0;
        },
        setMetricsRowsPerPage: (state, action) => {
            state.metricsRowsPerPage = action.payload;
            state.metricsTablePage = 0;
        },
        setRewardsTablePage: (state, action) => {
            state.rewardsTablePage = action.payload;
        },
        setRewardsTableSort: (state, action) => {
            const field = action.payload;
            const {field: oldField, direction: oldDirection} = state.rewardsTableSort;
            let direction = 'DESC';
            if (oldField === field) {
                direction = oldDirection === 'DESC' ? 'ASC' : 'DESC';
            }
            state.rewardsTableSort = {direction, field};
        },
        resetRewardsTableSort: (state) => {
            state.rewardsTableSort = {direction: '', field: ''};
        },
        setMetricsTablePage: (state, action) => {
            state.metricsTablePage = action.payload;
        },
        setMetricsTableSort: (state, action) => {
            const field = action.payload;
            const {field: oldField, direction: oldDirection} = state.metricsTableSort;
            let direction = 'DESC';
            if (oldField === field) {
                direction = oldDirection === 'DESC' ? 'ASC' : 'DESC';
            }
            state.metricsTableSort = {direction, field};
        },
        setMetricsTableFilters: (state, action) => {
            state.metricsTableFilters[action.payload.field] = {
                ...state.metricsTableFilters[action.payload.field],
                ...action.payload.value,
            };
            state.metricsTablePage = 0;
        },
        resetMetricsTableFilters: (state) => {
            state.metricsTableFilters = {};
        },
        setRewardsTableFilters: (state, action) => {
            state.rewardsTableFilters[action.payload.field] = {
                ...state.rewardsTableFilters[action.payload.field],
                ...action.payload.value,
            };
            state.rewardsTablePage = 0;
        },
        resetRewardsTableFilters: (state) => {
            state.rewardsTableFilters = {};
            state.rewardsTablePage = initialState.rewardsTablePage;
            state.rewardsRowsPerPage = initialState.rewardsRowsPerPage;
        },
        setShortcodesTablePage: (state, action) => {
            state.shortcodesTablePage = action.payload;
        },
        setShortcodesRowsPerPage: (state, action) => {
            state.shortcodesRowsPerPage = action.payload;
            state.shortcodesTablePage = 0;
        },
        setShortcodesTableSort: (state, action) => {
            const field = action.payload;
            const {field: oldField, direction: oldDirection} = state.shortcodesTableSort;
            let direction = 'DESC';
            if (oldField === field) {
                direction = oldDirection === 'DESC' ? 'ASC' : 'DESC';
            }
            state.shortcodesTableSort = {direction, field};
        },
        setShortcodesTableFilters: (state, action) => {
            state.shortcodesTableFilters[action.payload.field] = {
                ...state.shortcodesTableFilters[action.payload.field],
                ...action.payload.value,
            };
            state.shortcodesTablePage = 0;
        },
        resetShortcodesTableFilters: (state) => {
            state.shortcodesTableFilters = {};
            state.shortcodesTablePage = initialState.shortcodesTablePage;
            state.shortcodesRowsPerPage = initialState.shortcodesRowsPerPage;
        },
    },
});

export const {
    setPartnerTableSort,
    resetPartnerTableSort,
    setRewardsTablePage,
    setRewardsTableSort,
    setRewardsTableFilters,
    setRewardsRowsPerPage,
    resetRewardsTableSort,
    resetRewardsTableFilters,
    setMetricsTablePage,
    setMetricsTableSort,
    setMetricsTableFilters,
    setMetricsRowsPerPage,
    resetMetricsTableFilters,
    setShortcodesTablePage,
    setShortcodesTableSort,
    setShortcodesRowsPerPage,
    setShortcodesTableFilters,
    resetShortcodesTableFilters
} = authSlice.actions;

export default authSlice.reducer;
