import Skeleton from 'app/components/Skeleton/Skeleton';
import { RootState } from 'app/store';
import React from 'react';
import { useSelector } from 'react-redux';
import styles from './CampaignHeader.module.scss';

export default function CampaignHeader(): React.ReactElement {
  const campaign = useSelector<RootState, any>((state) => state.campaign.campaign);
  const isCampaignLoading = useSelector<RootState, boolean>((state) => state.campaign.isCampaignLoading);

  return (
    <div className={styles.root}>
      <Skeleton isLoading={isCampaignLoading}>
        {!isCampaignLoading && (
          <>
            <img src={campaign.campaign_image_url} alt="Campaign Image" className={styles.campaignImg} />
            <h3 className={styles.campaignName}>{campaign.campaign_name}</h3>

              {campaign?.properties?.campaign_type &&(
                  <h6 className={styles.campaignType}>{campaign?.properties?.campaign_type}</h6>
              )}
          </>
        )}
      </Skeleton>
    </div>
  );
}
