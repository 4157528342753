export const REWARD_STATUSES = {
  ACTIVE: 'Active',
  EXPIRED: 'Expired',
  PENDING: 'Pending',
  PAUSED: 'Paused',
  DISABLED: 'Disabled',
  EMPTY: 'Empty'
};

export const REWARDS_ROWS_NUMBERS = [
  { value: 20, label: '20' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
  { value: 200, label: '200' },
  { value: 0, label: 'All' }
];
