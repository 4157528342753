import Skeleton from 'app/components/Skeleton/Skeleton';
import { RootState } from 'app/store';
import Button from 'components/Button/Button';
import React from 'react';
import { useSelector } from 'react-redux';
import styles from './CampaignHeader.module.scss';
import { ReactComponent as BackIcon } from 'static/icons/arrow-small-left.svg';

export default function CampaignHeader(): React.ReactElement {
    const campaign = useSelector<RootState, { campaign_id: string; campaign_image_url: string; campaign_name: string, campaign_type: string }>(
        (state) => state.campaign.campaign
    );
    const isCampaignLoading = useSelector<RootState, boolean>((state) => state.campaign.isCampaignLoading);

    return (
        <div className={styles.root}>
            <Skeleton isLoading={isCampaignLoading}>
                {!isCampaignLoading && (
                    <>
                        <Button size="large" type="secondary" to={`/campaign/${campaign?.campaign_id}`}
                            iconLeft={<BackIcon height={24} />}
                            className={styles.backButton}>
                            Back
                        </Button>

                        <img src={campaign?.campaign_image_url} alt="Campaign Image" className={styles.campaignImg}/>
                        <h3 className={styles.campaignName}>{campaign?.campaign_name}</h3>
                    </>
                )}
            </Skeleton>
        </div>
    );
}
