import React from 'react';
import truncate from 'lodash/truncate'
import Tooltip from '../../../../../components/Tooltip/Tooltip';
import styles from './TableBody.module.scss';

interface Props {
    rows: React.ReactNode[][];
    headers?: any[];
}


export default function TableBody({ rows, headers }: Props): React.ReactElement {
    const findClickId = headers?.find((item: any) => item.field === 'click_id')?.field;
    const findNotes = headers?.find((item: any) => item.field === 'notes')?.field;

    return (
        <tbody className={styles.root}>
            {rows.map((cols, i) => (
                <tr className={styles.tableRow} key={i}>
                    {cols.map((col, index) => {
                        return (
                            <td key={index} className={styles.tableCell}>
                                {(typeof col === 'string' && findNotes && col.length > 40) ? (
                                    <Tooltip
                                        tooltip={col?.length > 250
                                            ? truncate(col.toString(), { 'length': 202 })
                                            : col}
                                        position={i === 0 ? 'bottom' : 'top'}
                                    >
                                        {truncate(col.toString(), { 'length': 42 })}
                                    </Tooltip>
                                ) : (typeof col === 'string' && !findClickId && col.length > 25) ? (
                                    <Tooltip
                                        tooltip={col}
                                        position='top'
                                    >
                                        {truncate(col.toString(), {
                                            'length': 25,
                                            'separator': ' '
                                        })}
                                    </Tooltip>
                                ) : (<>{col}</>)
                                }
                            </td>
                        )
                    })}
                </tr>
            ))
            }
            {rows.length === 0 && <tr className={styles.emptyTableRow}></tr>}
        </tbody >
    );
}
