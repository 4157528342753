import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'app/store';
import Skeleton from 'app/components/Skeleton/Skeleton';
import { getCampaigns } from '../../state/campaigns/CampaignsSlice';
import CampaignsList from './components/CampaignsList/CampaignsList';
import styles from './CampaignManager.module.scss';

export default function CampaignManager(): React.ReactElement {
  const dispatch = useDispatch();
  const isCampaignsLoading = useSelector<RootState, boolean>((state) => state.campaigns.isCampaignsLoading);

  useEffect(() => {
    dispatch(getCampaigns());
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.campaignsListHolder}>
        <Skeleton isLoading={isCampaignsLoading}>
          <CampaignsList/>
        </Skeleton>
      </div>
    </div>
  );
}
