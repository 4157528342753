import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep, forEach } from 'lodash';
import { RootState } from 'app/store';
import { trimAllValues } from 'app/utils/helpers';
import Skeleton from '../../../../app/components/Skeleton/Skeleton';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import {
    sendCodesData,
    setIsRewardCreated,
    setRewardsVoucherCodesFieldValue
} from '../../../../state/campaign/campaignSlice';
import styles from './VoucherCodesManualInput.module.scss';

export default function VoucherCodesManualInput(): React.ReactElement {
    const dispatch = useDispatch();
    const history = useHistory();

    const isRewardCreated = useSelector<RootState, boolean>((state) => state.campaign.isRewardCreated);
    const rewardId = useSelector<RootState, string | null>((state) => state.campaign.rewardId);
    const values = useSelector<RootState, { [key: string]: number | string[] | null | boolean }>(
        (state) => state.campaign.rewardVoucherCodes.values
    );
    const rewardsValues = useSelector<RootState, { [key: string]: string | number }>(
        (state) => state.campaign.rewardsSettings.values
    );
    const isCampaignLoading = useSelector<RootState, boolean>((state) => state.campaign.isCampaignLoading) || false;
    const [preservedData, setPreservedData] = useState(values);

    useEffect(() => {
        setPreservedData(trimAllValues(values))
    }, [values])

    useEffect(() => {
        if (isRewardCreated) {
            dispatch(setIsRewardCreated(false));
            const pathArray = location.pathname.split('/');

            if (rewardId) {
                if (pathArray[pathArray.length - 3] === 'edit') {
                    const pathArray = location.pathname.split('/');
                    pathArray[pathArray.length - 2] = rewardId;
                    history.push(pathArray.join('/'));
                } else {
                    history.push(`./edit/${rewardId}/editRewards`);
                }
            }
        }
        if (values.codes && (values.codes as string[]).length < 1) {
            dispatch(setRewardsVoucherCodesFieldValue({ field: 'codes', value: [''] }));
        }
        if (!values.win_max) {
            dispatch(setRewardsVoucherCodesFieldValue({ field: 'win_max', value: 1 }));
        }
    });
    const voucherCodeValue = (preservedData.codes as string[])[0] || '';

    const preserveCampaign = (key: string, value: string[] | number | null | boolean) => {
        const newData = cloneDeep(preservedData);
        newData[key] = value;
        setPreservedData(newData);
    }

    return (
        <Skeleton isLoading={isCampaignLoading}>
            <form
                className={styles.root}
                onSubmit={(e) => {
                    forEach(preservedData, (value: string[] | number | null | boolean, key) => {
                        if (value || (typeof value === 'boolean' && value === false)) {
                            dispatch(setRewardsVoucherCodesFieldValue({
                                field: key,
                                value
                            }))
                        }
                    })
                    setTimeout(() => {
                        dispatch(sendCodesData({ fileUploadCodes: false }));
                    }, 111)
                    e.preventDefault();
                }}
            >
                <div className={styles.formInputContainer}>
                    <label className={styles.inputLabel}>
                        Reward Name
                    </label>
                    <div className={styles.textInput}>
                        {rewardsValues.reward_name}
                    </div>
                </div>

                <div className={styles.form}>
                    <div className={styles.inputHolderGrow}>
                        <p className={styles.label}>Voucher code</p>
                        <Input
                            required
                            value={voucherCodeValue}
                            onChange={(e) => {
                                preserveCampaign('codes', [e.target.value])
                            }}
                            className={styles.bigInput}
                        />
                    </div>
                    <div className={styles.inputHolderGrow}>
                        <p className={styles.label}>Times voucher code can be used</p>
                        <Input
                            value={preservedData.win_max as number}
                            onChange={(e) => {
                                preserveCampaign('win_max',
                                    [e.target.value.replace(/[^0-9]/g, '')]
                                )
                            }}
                            min={1}
                            max={1000000000}
                            type="text"
                            className={styles.smallInput}
                        />
                    </div>
                </div>

                <div className={styles.checkboxInput}>
                    <label>
                        <input type="checkbox"
                            defaultChecked={!!values.not_visible_in_email}
                            onChange={(e) => {
                                preserveCampaign('not_visible_in_email', e.target.checked);
                            }}
                        />
                        <p className={styles.label}>Not visible in email</p>
                    </label>
                </div>

                <Button submit size="large" type="action" className={styles.submit}>
                    Add code
                </Button>
            </form>
        </Skeleton>
    );
}
