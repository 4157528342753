import React from 'react';
import styles from './CampaignContent.module.scss';
import CampaignRewards from '../CampaignRewards/CampaignRewards';

export default function CampaignContent(): React.ReactElement {
  return (
    <div className={styles.root}>
      <CampaignRewards />
    </div>
  );
}
