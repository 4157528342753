import { FILTER_TYPES } from 'app/components/Table/tableConstants';

export const campaignMetricsTableHeaders = [
  {
    field: 'created_at',
    displayName: 'Time',
  },
  {
    field: 'click_id',
    displayName: 'Click ID',
  },
  {
    field: 'code',
    displayName: 'Code',
  },
  {
    field: 'event_type',
    displayName: 'Event',
    filterType: FILTER_TYPES.SELECT,
    filterOptions: [
      { value: 'scan', label: 'Scan' },
      { value: 'register', label: 'Register' },
      { value: 'reward', label: 'Reward' },
      { value: 'login', label: 'Login' },
      { value: 'filtered', label: 'Filtered' },
      { value: 'shortcode', label: 'Shortcode' },
    ],
  },
  {
    field: 'country',
    displayName: 'Country',
  },
  {
    field: 'gender',
    displayName: 'Gender',
  },
  {
    field: 'age',
    displayName: 'Age',
  },
  {
    field: 'variant',
    displayName: 'Variant',
    filterType: FILTER_TYPES.TEXT,
  },
  {
    field: 'rewards',
    displayName: 'Rewards',
  },
];

export const rowsPerPage = 20;
