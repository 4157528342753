import React, { useRef, UIEvent } from 'react';

import TableBody from './components/TableBody/TableBody';
import TableHeader from './components/TableHeader/TableHeader';
import styles from './Table.module.scss';
import TablePagination from './components/TablePagination/TablePagination';
import throttle from 'lodash/throttle';
import classNames from 'classnames';

interface Props {
  rows: React.ReactNode[][];
  headers: any[];
  sort?: any;
  page?: number;
  rowsPerPage?: number;
  totalRows?: number;
  onSort?: any;
  onFilter?: (field: string, value: any) => void;
  onPageSelected?: any;
  onRowsNumberSelected?: any;
  rowsNumbers?: any;
  filters?: any;
}

export default function Table({
  rows,
  onPageSelected,
  totalRows,
  rowsPerPage,
  onRowsNumberSelected,
  rowsNumbers,
  headers,
  sort,
  filters,
  page,
  onSort,
  onFilter,
}: Props): React.ReactElement {
  const headerContainerRef = useRef<HTMLDivElement>(null);
  const bodyContainerRef = useRef<HTMLDivElement>(null);

  const handleBodyScroll = (e: UIEvent<HTMLDivElement>) => {
    const scrollTarget = e.target as HTMLDivElement;
    headerContainerRef.current!.scrollLeft = scrollTarget.scrollLeft;
  };

  const handleHeaderScroll = (e: UIEvent<HTMLDivElement>) => {
    const scrollTarget = e.target as HTMLDivElement;
    bodyContainerRef.current!.scrollLeft = scrollTarget.scrollLeft;
  };

  return (
    <div className={classNames(styles.root, 'table-root')}>
      <div ref={headerContainerRef} onScroll={throttle(handleHeaderScroll, 5)} className={styles.tableHeaderHolder}>
        <table cellSpacing={0} className={styles.table}>
          <TableHeader
            onFilter={onFilter}
            sort={sort}
            headers={headers}
            onSort={onSort}
            filters={filters}
          />
        </table>
      </div>
      <div ref={bodyContainerRef} onScroll={throttle(handleBodyScroll, 5)} className={styles.tableHolder}>
        <table cellSpacing={0} className={styles.table}>
          <TableHeader
            onFilter={onFilter}
            sort={sort}
            headers={headers}
            onSort={onSort}
            filters={filters}
            className={styles.hidden}
          />
          <TableBody rows={rows} headers={headers}/>
        </table>
        {rows.length === 0 && (
          <div className={styles.noEntriesBlock}>
            <div className={styles.stickyLabel}>No entries</div>
          </div>
        )}
      </div>

      {totalRows !== undefined && rowsPerPage !== undefined && page !== undefined && (
        <TablePagination
          onPageSelected={onPageSelected}
          totalRows={totalRows}
          rowsPerPage={rowsPerPage}
          onRowsNumberSelected={onRowsNumberSelected}
          rowsNumbers={rowsNumbers}
          page={page}
        />
      )}
    </div>
  );
}
