import { Notification } from 'app/interfaces/notification';
import { RootState } from 'app/store';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './Notifications.module.scss';
import { ReactComponent as CrossSmallIcon } from '../../../static/icons/cross-small.svg';
import { hideNotification } from 'state/ui/notifications/notificationsSlice';

const AUTO_HIDE_NOTIFICATION_DELAY_MS = 3000;

export default function Notifications(): React.ReactElement {
  const notifications = useSelector<RootState, Notification[]>((state) => state.notifications.notifications);
  const [prevNotificationsCount, setPrevNotificationsCount] = useState(0);
  const dispatch = useDispatch();

  const clearNotification = (id: string) => {
    dispatch(hideNotification(id));
  };

  useEffect(() => {
    if (notifications.length > 0 && notifications.length >= prevNotificationsCount) {
      const lastNotification = notifications[notifications.length - 1];
      setTimeout(() => {
        clearNotification(lastNotification.id);
      }, AUTO_HIDE_NOTIFICATION_DELAY_MS);
    }
    setPrevNotificationsCount(notifications.length);
  }, [notifications]);

  return (
    <div className={styles.root}>
      {notifications.map((notification, index) => (
          <div key={index} className={classnames(styles.notification)}>
            {notification && notification.text}
            <CrossSmallIcon
              onClick={() => clearNotification(notification.id)}
              className={classnames(styles.closeIcon)}
            ></CrossSmallIcon>
          </div>
      ))}
    </div>
  );
}
