import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchCampaigns, patchCampaign } from '../../api/campaignsApi';
import { getCampaign } from 'state/campaign/campaignSlice';

const initialState = {
  campaigns: [],
  currentlyEditedCampaign: null,
  error: '',
  isCampaignsLoading: false,
  isEditLoading: false,
};

export const getCampaigns = createAsyncThunk('campaigns/getCampaigns', async () => {
  const {
    data: {
      data: { campaigns },
    },
  } = await fetchCampaigns();
  return campaigns;
});

export const editCampaign = createAsyncThunk<any, any, any>(
  'campaigns/editCampaign',
  async (campaign, { rejectWithValue, dispatch }) => {
    try {
      await patchCampaign(campaign);
      dispatch(getCampaign(campaign.campaign_id))
    } catch (err: any) {
      const error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const authSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    setCurrentlyEditedCampaign(state, action) {
      state.currentlyEditedCampaign = action.payload;
    },
    setError(state, action) {
      state.error = action.payload || '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCampaigns.pending, (state) => {
      state.isCampaignsLoading = true;
    });
    builder.addCase(getCampaigns.fulfilled, (state, action) => {
      state.campaigns = action.payload;
      state.isCampaignsLoading = false;
    });
    builder.addCase(editCampaign.pending, (state) => {
      state.isEditLoading = true;
    });
    builder.addCase(editCampaign.fulfilled, (state) => {
      state.isEditLoading = false;
    });
    builder.addCase(editCampaign.rejected, (state, action: any) => {
      state.error = action.payload.error.title;
      state.isEditLoading = false;
    });
  },
});

export const { setCurrentlyEditedCampaign, setError } = authSlice.actions;

export default authSlice.reducer;
