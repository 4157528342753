import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  resetPartnerTableSort,
  resetRewardsTableFilters,
  resetRewardsTableSort
} from '../../../../state/ui/campaignDetails/campaignDetailsPageSlice';
import styles from './CampaignCard.module.scss';
export default function CampaignCard({ campaign }: { campaign: any }): React.ReactElement {
  const dispatch = useDispatch();
  const history = useHistory();

  const [cardImage, setCardImage] = useState('');

  useEffect(() => {
    setCardImage(campaign.campaign_image_url);
  }, [campaign.campaign_image_url]);

  const navigateToEdit = () => {
    dispatch(resetPartnerTableSort());
    dispatch(resetRewardsTableSort());
    dispatch(resetRewardsTableFilters());
    history.push(`campaign/${campaign.campaign_id}`);
  }

  return (
    <div onClick={navigateToEdit} className={styles.root}>
      <div className={styles.campaignInfo}>
        <div className={styles.campaignImageHolder}>
          <img
            src={cardImage || undefined}
            className={styles.campaignImage}
            onError={() => setCardImage('img/broken-image-placeholder.jfif')}
          />
        </div>
        <h2 className={styles.campaignName}>{campaign.campaign_name}</h2>
        {campaign.properties.campaign_type && (
          <h6 className={styles.campaignType}>{campaign.properties.campaign_type}</h6>
        )}
      </div>
    </div>
  );
}

CampaignCard.propTypes = {
  campaign: PropTypes.object,
};
