import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/store';
import { getRewardStatus } from 'app/utils/helpers';
import { LanguageData } from 'interfaces/campaignInterfaces';
import {
    duplicateRewardData, sendRewardData,
    setIsRewardCreated, setResetReward, setRewardConditionsFieldValue, setRewardSettingsFieldValue
} from '../../../../state/campaign/campaignSlice';
import PartnerPortal from '../PartnerPortal/PartnerPortal';
import VoucherCodesDropzone from '../VoucherCodesDropzone/VoucherCodesDropzone';
import VoucherCodesManualInput from '../VoucherCodesManualInput/VoucherCodesManualInput';
import Tabs from 'components/Tabs/Tabs';
import Tab from 'components/Tabs/Tab';
import RewardSettings from '../RewardSettings/RewardSettings';
import RewardConditions from '../RewardConditions/RewardConditions';
import RewardLanguages from '../RewardLanguages/RewardLanguages';
import styles from './CampaignRewards.module.scss';

interface TabGroup {
    name: string;
    group: number[];
    rewardStatusLabel?: string;
    enableAction?: (params?: any) => void;
    enableActionLabel?: string;
    action?: (params?: any) => void;
    actionLabel?: string;
}

export default function CampaignRewards(): React.ReactElement {
    const [tabIndex, setTabIndex] = useState(0);
    const dispatch = useDispatch();

    const campaign = useSelector<RootState, any | null>((state) => state.campaign.campaign);
    const reward_id = useSelector<RootState, string | null>((state) => state.campaign.rewardId);
    const values = useSelector<RootState, { [key: string]: string | number }>(
        (state) => state.campaign.rewardsSettings.values
    );
    const rewardsLanguagesValues = useSelector<RootState, { [key: string]: LanguageData }>(
        (state) => state.campaign.rewardsLanguages.values);

    const [rewardStatus, setRewardStatus] = useState<string>('');
    const [rewardError, setRewardError] = useState<boolean>(false);
    const [languagesError, setLanguagesError] = useState<boolean>(false);
    const [enablePartnerPortalTab, setEnablePartnerPortalTab] = useState<boolean>(false);
    const [isEnabledReward, setIsEnabledReward] = useState<boolean>(false);
    const dispatchValueChange = (name: string, value: string) => {
        dispatch(
            setRewardSettingsFieldValue({
                field: name,
                value,
            })
        );
    };

    const dispatchConditionValueChange = (name: string, value: string | string[] | null) =>
        dispatch(
            setRewardConditionsFieldValue({
                field: name,
                value,
            })
        );

    const duplicateAction = () => {
        dispatchValueChange('reward_name', 'COPY_OF_' + values.reward_name);
        dispatchValueChange('is_enabled', String(!isEnabledReward));
        dispatch(setIsRewardCreated(false));
        dispatchConditionValueChange('end_at', null);
        dispatchConditionValueChange('start_at', null);
        dispatch(duplicateRewardData());
    }

    const rewardEnableAction = () => {
        dispatchValueChange('is_enabled', String(!isEnabledReward));
        dispatch(sendRewardData());
    }

    const campaignTabGroups: TabGroup[] = [
        {
            name: 'Reward Status:',
            group: []
        },
        {
            name: 'Details',
            group: [0, 1, 2]
        },
        {
            name: 'Voucher Codes',
            group: [3, 4, 5]
        }
    ]

    if (reward_id) {
        campaignTabGroups[0].rewardStatusLabel = rewardStatus;
        campaignTabGroups[0].enableAction = () => rewardEnableAction();
        campaignTabGroups[0].enableActionLabel = `${isEnabledReward ? 'Enabled' : 'Disabled'}`;
        campaignTabGroups[1].action = () => duplicateAction();
        campaignTabGroups[1].actionLabel = 'Duplicate Reward';
    }

    const errorRewardSettings = !values?.reward_name || !values?.reward_probability;
    const errorRewardLanguages = !Object.keys(rewardsLanguagesValues).includes('en')
        || !!Object.values(rewardsLanguagesValues)?.filter((item: any) => !!item.reward_link && !item.reward_link_image)?.length
        || !!Object.values(rewardsLanguagesValues)?.filter((item: any) =>
            !item.reward_title || !item.reward_message || !item.reward_description || !item.reward_image_url)?.length;

    useEffect(() => {
        if (campaign && campaign.rewards) {
            const currentReward = campaign.rewards.find((item: any) => item.reward_id == reward_id);

            const currentRewardStatus = getRewardStatus(
                currentReward?.start_at,
                currentReward?.end_at,
                currentReward?.daily_limit,
                currentReward?.win_count_today,
                currentReward?.is_enabled,
                currentReward?.win_max,
                currentReward?.win_count
            );

            if (currentReward) {
                setRewardStatus(currentRewardStatus);
                setRewardError(!!errorRewardSettings)
                setLanguagesError(!!errorRewardLanguages);
                setIsEnabledReward(currentReward.is_enabled);
                setEnablePartnerPortalTab(Object.keys(currentReward.reward_attributes).includes('en'));
            }
        }
    }, [reward_id, campaign?.rewards, rewardsLanguagesValues, values]);

    useEffect(() => {
        return () => {
            dispatch(setResetReward())
        }
    }, [])

    return (
        <div className={styles.root}>
            <Tabs tabType="button"
                tabsGroup={campaignTabGroups}
                selectedIndex={tabIndex}
                onSelect={(i) => {
                    setTabIndex(i);
                }}>
                <Tab name="Rewards Settings" errorLabel={rewardError}>
                    <RewardSettings />
                </Tab>
                <Tab disabled={!reward_id} name="Conditions">
                    <RewardConditions />
                </Tab>
                <Tab disabled={!reward_id} name="Messages" errorLabel={languagesError}>
                    <RewardLanguages />
                </Tab>
                <Tab disabled={!reward_id} name="Add manually">
                    <VoucherCodesManualInput />
                </Tab>
                <Tab disabled={!reward_id} name="File upload">
                    <VoucherCodesDropzone />
                </Tab>
                <Tab
                    name='Add via Partner Portal'
                    disabledMessage={'Please ensure English Language message is added.'}
                    disabled={!enablePartnerPortalTab}
                >
                    <PartnerPortal />
                </Tab>
            </Tabs>
        </div>
    );
}
