import startOfDay from 'date-fns/startOfDay';
import subDays from 'date-fns/subDays';
import formatISO from 'date-fns/formatISO';
import format from 'date-fns/format';
import { DATE_FORMAT } from 'app/constants/dateConstants';
import moment from 'moment';

export const rewardTypes = [
  { value: 0, label: 'Everyone' },
  { value: 10, label: 'All' },
  { value: 20, label: 'Bronze' },
  { value: 30, label: 'Silver' },
  { value: 40, label: 'Gold' },
  { value: 50, label: 'Diamond' },
];

export const rewardsSettingsDefaultValues = {
  reward_name: '',
  reward_probability: '',
  reward_value: 0,
  partner_id: '',
};

export const rewardsConditionsDefaultValues = {
  start_at: null,
  end_at: formatISO(subDays(startOfDay(new Date()), 1)),
  target_countries: [],
  target_variant: '',
  daily_limit: '',
};

export const languages = [
  { value: 'en', label: 'English' },
  { value: 'fr', label: 'French' },
  { value: 'de', label: 'German' },
  { value: 'es', label: 'Spanish' },
  { value: 'it', label: 'Italian' },
  { value: 'zh', label: 'Simplified Chinese' },
  { value: 'ru', label: 'Russian' },
  { value: 'ar', label: 'Arabic' },
];

export const defaultLanguageData = {
  reward_link: '',
  reward_title: '',
  reward_message: '',
  reward_image_url: '',
  reward_description: '',
};

export const metricsFiltersDefaultValues = {
  from: format(startOfDay(new Date()), DATE_FORMAT),
  to: null,
};

export const OFFSETS = [
  '-12:00',
  '-11:00',
  '-10:00',
  '-09:30',
  '-08:00',
  '-07:00',
  '-06:00',
  '-05:00',
  '-04:00',
  '-03:30',
  '-03:00',
  '-02:00',
  '-01:00',
  '+00:00',
  '+01:00',
  '+02:00',
  '+03:00',
  '+03:30',
  '+04:00',
  '+04:30',
  '+05:00',
  '+05:30',
  '+05:45',
  '+06:00',
  '+06:30',
  '+07:00',
  '+08:00',
  '+08:45',
  '+09:00',
  '+09:30',
  '+10:00',
  '+10:30',
  '+11:00',
  '+12:00',
  '+12:45',
  '+13:00',
  '+14:00'
];

export const DEFAULT_TIME = moment(new Date()).format('HH:mm');

export const DEFAULT_TIMEZONE = '+00:00';
