import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/store';
import Skeleton from 'app/components/Skeleton/Skeleton';
import { setTabTouched } from '../../../../state/campaign/campaignSlice';
import Tab from 'components/Tabs/Tab';
import Tabs from 'components/Tabs/Tabs';
import CampaignPartners from '../../../Campaign/components/CampaignPartners/CampaignPartners';
import CampaignMetricsTable from '../CampaignMetricsTable/CampaignMetricsTable';
import CampaignRewards from '../CampaignRewards/CampaignRewards';
import CampaignShortcodesTable from '../CampaignShortcodes/CampaignShortcodesTable';
import styles from './CampaignDetailsContent.module.scss';
import EditCampaign from 'pages/CampaignManager/components/EditCampaign/EditCampaign';

export default function CampaignDetailsContent(): React.ReactElement {
    const dispatch = useDispatch();

    const partners = useSelector<RootState, any[]>((state) => state.campaign.campaign?.partners);
    const touched = useSelector<RootState, boolean>((state) => state.campaign.tabTouched);
    const isCampaignLoading = useSelector<RootState, boolean>((state) => state.campaign.isCampaignLoading);
    const isPartnersLoading = useSelector<RootState, boolean>((state) => state.campaign.isPartnersLoading);
    const isEditLoading = useSelector<RootState, boolean>((state) => state.campaigns.isEditLoading);

    const [disabledRewardTab, setDisabledRewardTab] = useState(true);
    const [disabledMetricsTab, setDisabledMetricsTab] = useState(true);
    const [disabledShortcodesTab, setDisabledShortcodesTab] = useState(true);
    const [tabIndex, setTabIndex] = useState(2);


    useEffect(() => {
        if (!isPartnersLoading && partners) {
            setTimeout(() => {
                const validPartner = (partners && partners.length > 0) &&
                    (partners[partners.length - 1].name && partners[partners.length - 1].name.length > 0) &&
                    (partners[partners.length - 1].created_at && partners[partners.length - 1].created_at.length > 0);
                setDisabledRewardTab(!validPartner);
                setDisabledMetricsTab(!validPartner);
                setDisabledShortcodesTab(!validPartner);
                if (!touched && validPartner) {
                    setTabIndex(2);
                    dispatch(setTabTouched(true));
                } else if (!validPartner) {
                    setTabIndex(1);
                }
            }, 333);
        }
    });

    return (
        <Tabs selectedIndex={tabIndex}
            onSelect={(i) => {
                dispatch(setTabTouched(true));
                setTabIndex(i);
            }}>
            <Tab name="Settings">
                <div className={styles.campaignRewardsHolder}>
                    <Skeleton isLoading={isEditLoading}>
                        <EditCampaign />
                    </Skeleton>
                </div>
            </Tab>
            <Tab name="Partners">
                <div className={styles.contentHolder}>
                    <CampaignPartners />
                </div>
            </Tab>
            <Tab name="Rewards" disabled={disabledRewardTab} disabledMessage={'Add at least 1 partner'}>
                <div className={styles.campaignRewardsHolder}>
                    <Skeleton isLoading={isCampaignLoading}>
                        {!isCampaignLoading && <CampaignRewards />}
                    </Skeleton>
                </div>
            </Tab>
            <Tab name="Shortcodes" disabled={disabledShortcodesTab} disabledMessage={'Add at least 1 partner'}>
                <div className={styles.campaignRewardsHolder}>
                    <Skeleton isLoading={isCampaignLoading}>
                        {!isCampaignLoading && <CampaignShortcodesTable />}
                    </Skeleton>
                </div>
            </Tab>
            <Tab name="Metrics" disabled={disabledMetricsTab} disabledMessage={'Add at least 1 partner'}>
                <div className={styles.campaignRewardsHolder}>
                    <Skeleton isLoading={isCampaignLoading}>
                        {!isCampaignLoading && <CampaignMetricsTable />}
                    </Skeleton>
                </div>
            </Tab>
        </Tabs>
    );
}
