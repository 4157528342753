import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import {resetRewardFormData, setTabTouched} from '../../../state/campaign/campaignSlice';
import { navigationList } from '../../constants/navigationConstants';
import styles from './Layout.module.scss';
import classNames from 'classnames';
import Button from 'components/Button/Button';
import {clearOrganisationCookies, logout} from 'app/utils/helpers';
import { useDispatch } from 'react-redux';
import { setOrgId } from 'features/auth/authSlice';
import Notifications from '../Notifications/Notifications';
import { ErrorView } from '../ErrorView/ErrorView';
import { useXelacoreModules } from 'app/hooks/useXelacoreModules';

interface Props {
  children: React.ReactNode;
}

export default function Layout({ children }: Props): React.ReactElement {
  const dispatch = useDispatch();
  const history = useHistory();
  const modules = useXelacoreModules();

  const currentPath = history.location.pathname;
  return (
    <ErrorView>
      <div className={styles.root}>
        <div className={styles.header}>
          <div className={styles.logoContainer}>
            <img src="/img/xelacore-logo-full.svg" alt="logo" className={styles.logoImg} />
          </div>
          <div className={styles.navigationMenu}>
            {navigationList.map(({ name, paths }) => (
              <Link
                className={classNames(styles.navLink, {
                  [styles.currentPath]: paths.some((p) => currentPath.startsWith(p)),
                })}
                key="name"
                to={paths[0]}
              >
                {name}
              </Link>
            ))}
          </div>
          <Button
            size="large"
            type="text"
            className={styles.logoutButton}
            onClick={() => {
              dispatch(setTabTouched(false));
              dispatch(setOrgId(null));
              dispatch(resetRewardFormData());
              clearOrganisationCookies();
            }}
          >
            Change organisation
          </Button>
          <Button size="large" type="text" className={styles.logoutButton} onClick={logout}>
            Log out
          </Button>
        </div>
        <div className={styles.notificationsHolder}>
          <Notifications></Notifications>
        </div>
        <div className={styles.content}>{children}</div>
      </div>
    </ErrorView>
  );
}
