export const countries = [
    {code: 'ad', name: 'Andorra'},
    {code: 'ae', name: 'United Arab Emirates'},
    {code: 'af', name: 'Afghanistan'},
    {code: 'ag', name: 'Antigua and Barbuda'},
    {code: 'ai', name: 'Anguilla'},
    {code: 'al', name: 'Albania'},
    {code: 'am', name: 'Armenia'},
    {code: 'ao', name: 'Angola'},
    {code: 'ap', name: 'Asia/Pacific Region'},
    {code: 'aq', name: 'Antarctica'},
    {code: 'ar', name: 'Argentina'},
    {code: 'as', name: 'American Samoa'},
    {code: 'at', name: 'Austria'},
    {code: 'au', name: 'Australia'},
    {code: 'aw', name: 'Aruba'},
    {code: 'ax', name: 'Aland Islands'},
    {code: 'az', name: 'Azerbaijan'},
    {code: 'ba', name: 'Bosnia and Herzegovina'},
    {code: 'bb', name: 'Barbados'},
    {code: 'bd', name: 'Bangladesh'},
    {code: 'be', name: 'Belgium'},
    {code: 'bf', name: 'Burkina Faso'},
    {code: 'bg', name: 'Bulgaria'},
    {code: 'bh', name: 'Bahrain'},
    {code: 'bi', name: 'Burundi'},
    {code: 'bj', name: 'Benin'},
    {code: 'bl', name: 'Saint Barthelemey'},
    {code: 'bm', name: 'Bermuda'},
    {code: 'bn', name: 'Brunei Darussalam'},
    {code: 'bo', name: 'Bolivia'},
    {code: 'bq', name: 'Bonaire'},
    {code: 'br', name: 'Brazil'},
    {code: 'bs', name: 'Bahamas'},
    {code: 'bt', name: 'Bhutan'},
    {code: 'bv', name: 'Bouvet Island'},
    {code: 'bw', name: 'Botswana'},
    {code: 'by', name: 'Belarus'},
    {code: 'bz', name: 'Belize'},
    {code: 'ca', name: 'Canada'},
    {code: 'cc', name: 'Cocos (Keeling) Islands'},
    {code: 'cd', name: 'Congo, the Democratic Republic of the'},
    {code: 'cf', name: 'Central African Republic'},
    {code: 'cg', name: 'Congo'},
    {code: 'ch', name: 'Switzerland'},
    {code: 'ci', name: 'Cote d\'Ivoire'},
    {code: 'ck', name: 'Cook Islands'},
    {code: 'cl', name: 'Chile'},
    {code: 'cm', name: 'Cameroon'},
    {code: 'cn', name: 'China'},
    {code: 'co', name: 'Colombia'},
    {code: 'cr', name: 'Costa Rica'},
    {code: 'cu', name: 'Cuba'},
    {code: 'cv', name: 'Cape Verde'},
    {code: 'cw', name: 'Curacao'},
    {code: 'cx', name: 'Christmas Island'},
    {code: 'cy', name: 'Cyprus'},
    {code: 'cz', name: 'Czech Republic'},
    {code: 'de', name: 'Germany'},
    {code: 'dj', name: 'Djibouti'},
    {code: 'dk', name: 'Denmark'},
    {code: 'dm', name: 'Dominica'},
    {code: 'do', name: 'Dominican Republic'},
    {code: 'dz', name: 'Algeria'},
    {code: 'ec', name: 'Ecuador'},
    {code: 'ee', name: 'Estonia'},
    {code: 'eg', name: 'Egypt'},
    {code: 'eh', name: 'Western Sahara'},
    {code: 'er', name: 'Eritrea'},
    {code: 'es', name: 'Spain'},
    {code: 'et', name: 'Ethiopia'},
    {code: 'eu', name: 'Europe'},
    {code: 'fi', name: 'Finland'},
    {code: 'fj', name: 'Fiji'},
    {code: 'fk', name: 'Falkland Islands (Malvinas)'},
    {code: 'fm', name: 'Micronesia, Federated States of'},
    {code: 'fo', name: 'Faroe Islands'},
    {code: 'fr', name: 'France'},
    {code: 'ga', name: 'Gabon'},
    {code: 'gb', name: 'United Kingdom'},
    {code: 'gd', name: 'Grenada'},
    {code: 'ge', name: 'Georgia'},
    {code: 'gf', name: 'French Guiana'},
    {code: 'gg', name: 'Guernsey'},
    {code: 'gh', name: 'Ghana'},
    {code: 'gi', name: 'Gibraltar'},
    {code: 'gl', name: 'Greenland'},
    {code: 'gm', name: 'Gambia'},
    {code: 'gn', name: 'Guinea'},
    {code: 'gp', name: 'Guadeloupe'},
    {code: 'gq', name: 'Equatorial Guinea'},
    {code: 'gr', name: 'Greece'},
    {code: 'gs', name: 'South Georgia and the South Sandwich Islands'},
    {code: 'gt', name: 'Guatemala'},
    {code: 'gu', name: 'Guam'},
    {code: 'gw', name: 'Guinea-Bissau'},
    {code: 'gy', name: 'Guyana'},
    {code: 'hk', name: 'Hong Kong'},
    {code: 'hm', name: 'Heard Island and McDonald Islands'},
    {code: 'hn', name: 'Honduras'},
    {code: 'hr', name: 'Croatia'},
    {code: 'ht', name: 'Haiti'},
    {code: 'hu', name: 'Hungary'},
    {code: 'id', name: 'Indonesia'},
    {code: 'ie', name: 'Ireland'},
    {code: 'il', name: 'Israel'},
    {code: 'im', name: 'Isle of Man'},
    {code: 'in', name: 'India'},
    {code: 'io', name: 'British Indian Ocean Territory'},
    {code: 'iq', name: 'Iraq'},
    {code: 'ir', name: 'Iran, Islamic Republic of'},
    {code: 'is', name: 'Iceland'},
    {code: 'it', name: 'Italy'},
    {code: 'je', name: 'Jersey'},
    {code: 'jm', name: 'Jamaica'},
    {code: 'jo', name: 'Jordan'},
    {code: 'jp', name: 'Japan'},
    {code: 'ke', name: 'Kenya'},
    {code: 'kg', name: 'Kyrgyzstan'},
    {code: 'kh', name: 'Cambodia'},
    {code: 'ki', name: 'Kiribati'},
    {code: 'km', name: 'Comoros'},
    {code: 'kn', name: 'Saint Kitts and Nevis'},
    {code: 'kp', name: 'Korea, Democratic People\'s Republic of'},
    {code: 'kr', name: 'Korea, Republic of'},
    {code: 'kw', name: 'Kuwait'},
    {code: 'ky', name: 'Cayman Islands'},
    {code: 'kz', name: 'Kazakhstan'},
    {code: 'la', name: 'Lao People\'s Democratic Republic'},
    {code: 'lb', name: 'Lebanon'},
    {code: 'lc', name: 'Saint Lucia'},
    {code: 'li', name: 'Liechtenstein'},
    {code: 'lk', name: 'Sri Lanka'},
    {code: 'lr', name: 'Liberia'},
    {code: 'ls', name: 'Lesotho'},
    {code: 'lt', name: 'Lithuania'},
    {code: 'lu', name: 'Luxembourg'},
    {code: 'lv', name: 'Latvia'},
    {code: 'ly', name: 'Libyan Arab Jamahiriya'},
    {code: 'ma', name: 'Morocco'},
    {code: 'mc', name: 'Monaco'},
    {code: 'md', name: 'Moldova, Republic of'},
    {code: 'me', name: 'Montenegro'},
    {code: 'mf', name: 'Saint Martin'},
    {code: 'mg', name: 'Madagascar'},
    {code: 'mh', name: 'Marshall Islands'},
    {code: 'mk', name: 'Macedonia, the former Yugoslav Republic of'},
    {code: 'ml', name: 'Mali'},
    {code: 'mm', name: 'Myanmar'},
    {code: 'mn', name: 'Mongolia'},
    {code: 'mo', name: 'Macao'},
    {code: 'mp', name: 'Northern Mariana Islands'},
    {code: 'mq', name: 'Martinique'},
    {code: 'mr', name: 'Mauritania'},
    {code: 'ms', name: 'Montserrat'},
    {code: 'mt', name: 'Malta'},
    {code: 'mu', name: 'Mauritius'},
    {code: 'mv', name: 'Maldives'},
    {code: 'mw', name: 'Malawi'},
    {code: 'mx', name: 'Mexico'},
    {code: 'my', name: 'Malaysia'},
    {code: 'mz', name: 'Mozambique'},
    {code: 'na', name: 'Namibia'},
    {code: 'nc', name: 'New Caledonia'},
    {code: 'ne', name: 'Niger'},
    {code: 'nf', name: 'Norfolk Island'},
    {code: 'ng', name: 'Nigeria'},
    {code: 'ni', name: 'Nicaragua'},
    {code: 'nl', name: 'Netherlands'},
    {code: 'no', name: 'Norway'},
    {code: 'np', name: 'Nepal'},
    {code: 'nr', name: 'Nauru'},
    {code: 'nu', name: 'Niue'},
    {code: 'nz', name: 'New Zealand'},
    {code: 'om', name: 'Oman'},
    {code: 'pa', name: 'Panama'},
    {code: 'pe', name: 'Peru'},
    {code: 'pf', name: 'French Polynesia'},
    {code: 'pg', name: 'Papua New Guinea'},
    {code: 'ph', name: 'Philippines'},
    {code: 'pk', name: 'Pakistan'},
    {code: 'pl', name: 'Poland'},
    {code: 'pm', name: 'Saint Pierre and Miquelon'},
    {code: 'pn', name: 'Pitcairn'},
    {code: 'pr', name: 'Puerto Rico'},
    {code: 'ps', name: 'Palestinian Territory, Occupied'},
    {code: 'pt', name: 'Portugal'},
    {code: 'pw', name: 'Palau'},
    {code: 'py', name: 'Paraguay'},
    {code: 'qa', name: 'Qatar'},
    {code: 're', name: 'Reunion'},
    {code: 'ro', name: 'Romania'},
    {code: 'rs', name: 'Serbia'},
    {code: 'ru', name: 'Russian Federation'},
    {code: 'rw', name: 'Rwanda'},
    {code: 'sa', name: 'Saudi Arabia'},
    {code: 'sb', name: 'Solomon Islands'},
    {code: 'sc', name: 'Seychelles'},
    {code: 'sd', name: 'Sudan'},
    {code: 'se', name: 'Sweden'},
    {code: 'sg', name: 'Singapore'},
    {code: 'sh', name: 'Saint Helena, Ascension and Tristan da Cunha'},
    {code: 'si', name: 'Slovenia'},
    {code: 'sj', name: 'Svalbard and Jan Mayen'},
    {code: 'sk', name: 'Slovakia'},
    {code: 'sl', name: 'Sierra Leone'},
    {code: 'sm', name: 'San Marino'},
    {code: 'sn', name: 'Senegal'},
    {code: 'so', name: 'Somalia'},
    {code: 'sr', name: 'Suriname'},
    {code: 'ss', name: 'South Sudan'},
    {code: 'st', name: 'Sao Tome and Principe'},
    {code: 'sv', name: 'El Salvador'},
    {code: 'sx', name: 'Sint Maarten'},
    {code: 'sy', name: 'Syrian Arab Republic'},
    {code: 'sz', name: 'Swaziland'},
    {code: 'tc', name: 'Turks and Caicos Islands'},
    {code: 'td', name: 'Chad'},
    {code: 'tf', name: 'French Southern Territories'},
    {code: 'tg', name: 'Togo'},
    {code: 'th', name: 'Thailand'},
    {code: 'tj', name: 'Tajikistan'},
    {code: 'tk', name: 'Tokelau'},
    {code: 'tl', name: 'Timor-Leste'},
    {code: 'tm', name: 'Turkmenistan'},
    {code: 'tn', name: 'Tunisia'},
    {code: 'to', name: 'Tonga'},
    {code: 'tr', name: 'Turkey'},
    {code: 'tt', name: 'Trinidad and Tobago'},
    {code: 'tv', name: 'Tuvalu'},
    {code: 'tw', name: 'Taiwan'},
    {code: 'tz', name: 'Tanzania, United Republic of'},
    {code: 'ua', name: 'Ukraine'},
    {code: 'ug', name: 'Uganda'},
    {code: 'um', name: 'United States Minor Outlying Islands'},
    {code: 'us', name: 'United States'},
    {code: 'uy', name: 'Uruguay'},
    {code: 'uz', name: 'Uzbekistan'},
    {code: 'va', name: 'Holy See (Vatican City State)'},
    {code: 'vc', name: 'Saint Vincent and the Grenadines'},
    {code: 've', name: 'Venezuela'},
    {code: 'vg', name: 'Virgin Islands, British'},
    {code: 'vi', name: 'Virgin Islands, U.S.'},
    {code: 'vn', name: 'Vietnam'},
    {code: 'vu', name: 'Vanuatu'},
    {code: 'wf', name: 'Wallis and Futuna'},
    {code: 'ws', name: 'Samoa'},
    {code: 'ye', name: 'Yemen'},
    {code: 'yt', name: 'Mayotte'},
    {code: 'za', name: 'South Africa'},
    {code: 'zm', name: 'Zambia'},
    {code: 'zw', name: 'Zimbabwe'},
];

export const flatCountries = [
    {
        'value': 'af',
        'label': 'Afghanistan'
    },
    {
        'value': 'am',
        'label': 'Armenia'
    },
    {
        'value': 'az',
        'label': 'Azerbaijan'
    },
    {
        'value': 'bh',
        'label': 'Bahrain'
    },
    {
        'value': 'bd',
        'label': 'Bangladesh'
    },
    {
        'value': 'bt',
        'label': 'Bhutan'
    },
    {
        'value': 'io',
        'label': 'British Indian Ocean Territory'
    },
    {
        'value': 'bn',
        'label': 'Brunei Darussalam'
    },
    {
        'value': 'kh',
        'label': 'Cambodia'
    },
    {
        'value': 'cn',
        'label': 'China'
    },
    {
        'value': 'cx',
        'label': 'Christmas Island'
    },
    {
        'value': 'cc',
        'label': 'Cocos (Keeling) Islands'
    },
    {
        'value': 'ge',
        'label': 'Georgia'
    },
    {
        'value': 'hk',
        'label': 'Hong Kong'
    },
    {
        'value': 'in',
        'label': 'India'
    },
    {
        'value': 'id',
        'label': 'Indonesia'
    },
    {
        'value': 'ir',
        'label': 'Iran, Islamic Republic of'
    },
    {
        'value': 'iq',
        'label': 'Iraq'
    },
    {
        'value': 'il',
        'label': 'Israel'
    },
    {
        'value': 'jp',
        'label': 'Japan'
    },
    {
        'value': 'jo',
        'label': 'Jordan'
    },
    {
        'value': 'kz',
        'label': 'Kazakhstan'
    },
    {
        'value': 'kp',
        'label': 'Korea, Democratic People\'s Republic of'
    },
    {
        'value': 'kr',
        'label': 'Korea, Republic of'
    },
    {
        'value': 'kw',
        'label': 'Kuwait'
    },
    {
        'value': 'kg',
        'label': 'Kyrgyzstan'
    },
    {
        'value': 'la',
        'label': 'Lao People\'s Democratic Republic'
    },
    {
        'value': 'lb',
        'label': 'Lebanon'
    },
    {
        'value': 'mo',
        'label': 'Macao'
    },
    {
        'value': 'my',
        'label': 'Malaysia'
    },
    {
        'value': 'mv',
        'label': 'Maldives'
    },
    {
        'value': 'mn',
        'label': 'Mongolia'
    },
    {
        'value': 'mm',
        'label': 'Myanmar'
    },
    {
        'value': 'np',
        'label': 'Nepal'
    },
    {
        'value': 'om',
        'label': 'Oman'
    },
    {
        'value': 'pk',
        'label': 'Pakistan'
    },
    {
        'value': 'ps',
        'label': 'Palestinian Territory, Occupied'
    },
    {
        'value': 'ph',
        'label': 'Philippines'
    },
    {
        'value': 'qa',
        'label': 'Qatar'
    },
    {
        'value': 'sa',
        'label': 'Saudi Arabia'
    },
    {
        'value': 'sg',
        'label': 'Singapore'
    },
    {
        'value': 'lk',
        'label': 'Sri Lanka'
    },
    {
        'value': 'sy',
        'label': 'Syrian Arab Republic'
    },
    {
        'value': 'tw',
        'label': 'Taiwan'
    },
    {
        'value': 'tj',
        'label': 'Tajikistan'
    },
    {
        'value': 'th',
        'label': 'Thailand'
    },
    {
        'value': 'tr',
        'label': 'Turkey'
    },
    {
        'value': 'tm',
        'label': 'Turkmenistan'
    },
    {
        'value': 'ae',
        'label': 'United Arab Emirates'
    },
    {
        'value': 'uz',
        'label': 'Uzbekistan'
    },
    {
        'value': 'vn',
        'label': 'Vietnam'
    },
    {
        'value': 'ye',
        'label': 'Yemen'
    },
    {
        'value': 'al',
        'label': 'Albania'
    },
    {
        'value': 'ad',
        'label': 'Andorra'
    },
    {
        'value': 'at',
        'label': 'Austria'
    },
    {
        'value': 'by',
        'label': 'Belarus'
    },
    {
        'value': 'be',
        'label': 'Belgium'
    },
    {
        'value': 'ba',
        'label': 'Bosnia and Herzegovina'
    },
    {
        'value': 'bg',
        'label': 'Bulgaria'
    },
    {
        'value': 'hr',
        'label': 'Croatia'
    },
    {
        'value': 'cy',
        'label': 'Cyprus'
    },
    {
        'value': 'cz',
        'label': 'Czech Republic'
    },
    {
        'value': 'dk',
        'label': 'Denmark'
    },
    {
        'value': 'ee',
        'label': 'Estonia'
    },
    {
        'value': 'fo',
        'label': 'Faroe Islands'
    },
    {
        'value': 'fi',
        'label': 'Finland'
    },
    {
        'value': 'fr',
        'label': 'France'
    },
    {
        'value': 'de',
        'label': 'Germany'
    },
    {
        'value': 'gi',
        'label': 'Gibraltar'
    },
    {
        'value': 'gr',
        'label': 'Greece'
    },
    {
        'value': 'gg',
        'label': 'Guernsey'
    },
    {
        'value': 'va',
        'label': 'Holy See (Vatican City State)'
    },
    {
        'value': 'hu',
        'label': 'Hungary'
    },
    {
        'value': 'is',
        'label': 'Iceland'
    },
    {
        'value': 'ie',
        'label': 'Ireland'
    },
    {
        'value': 'im',
        'label': 'Isle of Man'
    },
    {
        'value': 'it',
        'label': 'Italy'
    },
    {
        'value': 'je',
        'label': 'Jersey'
    },
    {
        'value': 'lv',
        'label': 'Latvia'
    },
    {
        'value': 'li',
        'label': 'Liechtenstein'
    },
    {
        'value': 'lt',
        'label': 'Lithuania'
    },
    {
        'value': 'lu',
        'label': 'Luxembourg'
    },
    {
        'value': 'mk',
        'label': 'Macedonia, the former Yugoslav Republic of'
    },
    {
        'value': 'mt',
        'label': 'Malta'
    },
    {
        'value': 'md',
        'label': 'Moldova, Republic of'
    },
    {
        'value': 'mc',
        'label': 'Monaco'
    },
    {
        'value': 'me',
        'label': 'Montenegro'
    },
    {
        'value': 'nl',
        'label': 'Netherlands'
    },
    {
        'value': 'no',
        'label': 'Norway'
    },
    {
        'value': 'pl',
        'label': 'Poland'
    },
    {
        'value': 'pt',
        'label': 'Portugal'
    },
    {
        'value': 'ro',
        'label': 'Romania'
    },
    {
        'value': 'ru',
        'label': 'Russian Federation'
    },
    {
        'value': 'sm',
        'label': 'San Marino'
    },
    {
        'value': 'rs',
        'label': 'Serbia'
    },
    {
        'value': 'sk',
        'label': 'Slovakia'
    },
    {
        'value': 'si',
        'label': 'Slovenia'
    },
    {
        'value': 'es',
        'label': 'Spain'
    },
    {
        'value': 'sj',
        'label': 'Svalbard and Jan Mayen'
    },
    {
        'value': 'se',
        'label': 'Sweden'
    },
    {
        'value': 'ch',
        'label': 'Switzerland'
    },
    {
        'value': 'ua',
        'label': 'Ukraine'
    },
    {
        'value': 'gb',
        'label': 'United Kingdom'
    },
    {
        'value': 'dz',
        'label': 'Algeria'
    },
    {
        'value': 'ao',
        'label': 'Angola'
    },
    {
        'value': 'bj',
        'label': 'Benin'
    },
    {
        'value': 'bw',
        'label': 'Botswana'
    },
    {
        'value': 'bf',
        'label': 'Burkina Faso'
    },
    {
        'value': 'bi',
        'label': 'Burundi'
    },
    {
        'value': 'cm',
        'label': 'Cameroon'
    },
    {
        'value': 'cv',
        'label': 'Cape Verde'
    },
    {
        'value': 'cf',
        'label': 'Central African Republic'
    },
    {
        'value': 'td',
        'label': 'Chad'
    },
    {
        'value': 'km',
        'label': 'Comoros'
    },
    {
        'value': 'cg',
        'label': 'Congo'
    },
    {
        'value': 'cd',
        'label': 'Congo, the Democratic Republic of the'
    },
    {
        'value': 'ci',
        'label': 'Cote d\'Ivoire'
    },
    {
        'value': 'dj',
        'label': 'Djibouti'
    },
    {
        'value': 'eg',
        'label': 'Egypt'
    },
    {
        'value': 'gq',
        'label': 'Equatorial Guinea'
    },
    {
        'value': 'er',
        'label': 'Eritrea'
    },
    {
        'value': 'et',
        'label': 'Ethiopia'
    },
    {
        'value': 'ga',
        'label': 'Gabon'
    },
    {
        'value': 'gm',
        'label': 'Gambia'
    },
    {
        'value': 'gh',
        'label': 'Ghana'
    },
    {
        'value': 'gn',
        'label': 'Guinea'
    },
    {
        'value': 'gw',
        'label': 'Guinea-Bissau'
    },
    {
        'value': 'ke',
        'label': 'Kenya'
    },
    {
        'value': 'ls',
        'label': 'Lesotho'
    },
    {
        'value': 'lr',
        'label': 'Liberia'
    },
    {
        'value': 'ly',
        'label': 'Libyan Arab Jamahiriya'
    },
    {
        'value': 'mg',
        'label': 'Madagascar'
    },
    {
        'value': 'mw',
        'label': 'Malawi'
    },
    {
        'value': 'ml',
        'label': 'Mali'
    },
    {
        'value': 'mr',
        'label': 'Mauritania'
    },
    {
        'value': 'mu',
        'label': 'Mauritius'
    },
    {
        'value': 'yt',
        'label': 'Mayotte'
    },
    {
        'value': 'ma',
        'label': 'Morocco'
    },
    {
        'value': 'mz',
        'label': 'Mozambique'
    },
    {
        'value': 'na',
        'label': 'Namibia'
    },
    {
        'value': 'ne',
        'label': 'Niger'
    },
    {
        'value': 'ng',
        'label': 'Nigeria'
    },
    {
        'value': 're',
        'label': 'Reunion'
    },
    {
        'value': 'rw',
        'label': 'Rwanda'
    },
    {
        'value': 'sh',
        'label': 'Saint Helena, Ascension and Tristan da Cunha'
    },
    {
        'value': 'st',
        'label': 'Sao Tome and Principe'
    },
    {
        'value': 'sn',
        'label': 'Senegal'
    },
    {
        'value': 'sc',
        'label': 'Seychelles'
    },
    {
        'value': 'sl',
        'label': 'Sierra Leone'
    },
    {
        'value': 'so',
        'label': 'Somalia'
    },
    {
        'value': 'za',
        'label': 'South Africa'
    },
    {
        'value': 'sd',
        'label': 'Sudan'
    },
    {
        'value': 'ss',
        'label': 'South Sudan'
    },
    {
        'value': 'sz',
        'label': 'Swaziland'
    },
    {
        'value': 'tz',
        'label': 'Tanzania, United Republic of'
    },
    {
        'value': 'tg',
        'label': 'Togo'
    },
    {
        'value': 'tn',
        'label': 'Tunisia'
    },
    {
        'value': 'ug',
        'label': 'Uganda'
    },
    {
        'value': 'eh',
        'label': 'Western Sahara'
    },
    {
        'value': 'zm',
        'label': 'Zambia'
    },
    {
        'value': 'zw',
        'label': 'Zimbabwe'
    },
    {
        'value': 'as',
        'label': 'American Samoa'
    },
    {
        'value': 'au',
        'label': 'Australia'
    },
    {
        'value': 'ck',
        'label': 'Cook Islands'
    },
    {
        'value': 'fj',
        'label': 'Fiji'
    },
    {
        'value': 'pf',
        'label': 'French Polynesia'
    },
    {
        'value': 'gu',
        'label': 'Guam'
    },
    {
        'value': 'ki',
        'label': 'Kiribati'
    },
    {
        'value': 'mh',
        'label': 'Marshall Islands'
    },
    {
        'value': 'fm',
        'label': 'Micronesia, Federated States of'
    },
    {
        'value': 'nr',
        'label': 'Nauru'
    },
    {
        'value': 'nc',
        'label': 'New Caledonia'
    },
    {
        'value': 'nz',
        'label': 'New Zealand'
    },
    {
        'value': 'nu',
        'label': 'Niue'
    },
    {
        'value': 'nf',
        'label': 'Norfolk Island'
    },
    {
        'value': 'mp',
        'label': 'Northern Mariana Islands'
    },
    {
        'value': 'pw',
        'label': 'Palau'
    },
    {
        'value': 'pg',
        'label': 'Papua New Guinea'
    },
    {
        'value': 'pn',
        'label': 'Pitcairn'
    },
    {
        'value': 'ws',
        'label': 'Samoa'
    },
    {
        'value': 'sb',
        'label': 'Solomon Islands'
    },
    {
        'value': 'tl',
        'label': 'Timor-Leste'
    },
    {
        'value': 'tk',
        'label': 'Tokelau'
    },
    {
        'value': 'to',
        'label': 'Tonga'
    },
    {
        'value': 'tv',
        'label': 'Tuvalu'
    },
    {
        'value': 'um',
        'label': 'United States Minor Outlying Islands'
    },
    {
        'value': 'vu',
        'label': 'Vanuatu'
    },
    {
        'value': 'wf',
        'label': 'Wallis and Futuna'
    },
    {
        'value': 'ai',
        'label': 'Anguilla'
    },
    {
        'value': 'ag',
        'label': 'Antigua and Barbuda'
    },
    {
        'value': 'aw',
        'label': 'Aruba'
    },
    {
        'value': 'bs',
        'label': 'Bahamas'
    },
    {
        'value': 'bb',
        'label': 'Barbados'
    },
    {
        'value': 'bz',
        'label': 'Belize'
    },
    {
        'value': 'bm',
        'label': 'Bermuda'
    },
    {
        'value': 'ca',
        'label': 'Canada'
    },
    {
        'value': 'ky',
        'label': 'Cayman Islands'
    },
    {
        'value': 'cr',
        'label': 'Costa Rica'
    },
    {
        'value': 'cu',
        'label': 'Cuba'
    },
    {
        'value': 'dm',
        'label': 'Dominica'
    },
    {
        'value': 'do',
        'label': 'Dominican Republic'
    },
    {
        'value': 'sv',
        'label': 'El Salvador'
    },
    {
        'value': 'gl',
        'label': 'Greenland'
    },
    {
        'value': 'gd',
        'label': 'Grenada'
    },
    {
        'value': 'gp',
        'label': 'Guadeloupe'
    },
    {
        'value': 'gt',
        'label': 'Guatemala'
    },
    {
        'value': 'ht',
        'label': 'Haiti'
    },
    {
        'value': 'hn',
        'label': 'Honduras'
    },
    {
        'value': 'jm',
        'label': 'Jamaica'
    },
    {
        'value': 'mq',
        'label': 'Martinique'
    },
    {
        'value': 'mx',
        'label': 'Mexico'
    },
    {
        'value': 'ms',
        'label': 'Montserrat'
    },
    {
        'value': 'ni',
        'label': 'Nicaragua'
    },
    {
        'value': 'pa',
        'label': 'Panama'
    },
    {
        'value': 'pr',
        'label': 'Puerto Rico'
    },
    {
        'value': 'kn',
        'label': 'Saint Kitts and Nevis'
    },
    {
        'value': 'lc',
        'label': 'Saint Lucia'
    },
    {
        'value': 'pm',
        'label': 'Saint Pierre and Miquelon'
    },
    {
        'value': 'vc',
        'label': 'Saint Vincent and the Grenadines'
    },
    {
        'value': 'tt',
        'label': 'Trinidad and Tobago'
    },
    {
        'value': 'tc',
        'label': 'Turks and Caicos Islands'
    },
    {
        'value': 'us',
        'label': 'United States'
    },
    {
        'value': 'vg',
        'label': 'Virgin Islands, British'
    },
    {
        'value': 'vi',
        'label': 'Virgin Islands, U.S.'
    },
    {
        'value': 'aq',
        'label': 'Antarctica'
    },
    {
        'value': 'bv',
        'label': 'Bouvet Island'
    },
    {
        'value': 'tf',
        'label': 'French Southern Territories'
    },
    {
        'value': 'hm',
        'label': 'Heard Island and McDonald Islands'
    },
    {
        'value': 'gs',
        'label': 'South Georgia and the South Sandwich Islands'
    },
    {
        'value': 'ar',
        'label': 'Argentina'
    },
    {
        'value': 'bo',
        'label': 'Bolivia'
    },
    {
        'value': 'br',
        'label': 'Brazil'
    },
    {
        'value': 'cl',
        'label': 'Chile'
    },
    {
        'value': 'co',
        'label': 'Colombia'
    },
    {
        'value': 'ec',
        'label': 'Ecuador'
    },
    {
        'value': 'fk',
        'label': 'Falkland Islands (Malvinas)'
    },
    {
        'value': 'gf',
        'label': 'French Guiana'
    },
    {
        'value': 'gy',
        'label': 'Guyana'
    },
    {
        'value': 'py',
        'label': 'Paraguay'
    },
    {
        'value': 'pe',
        'label': 'Peru'
    },
    {
        'value': 'sr',
        'label': 'Suriname'
    },
    {
        'value': 'uy',
        'label': 'Uruguay'
    },
    {
        'value': 've',
        'label': 'Venezuela'
    }
];

export const groupedCountries = [
    {
        'label': 'Asia',
        'options': [
            {
                'value': 'af',
                'label': 'Afghanistan'
            },
            {
                'value': 'am',
                'label': 'Armenia'
            },
            {
                'value': 'az',
                'label': 'Azerbaijan'
            },
            {
                'value': 'bh',
                'label': 'Bahrain'
            },
            {
                'value': 'bd',
                'label': 'Bangladesh'
            },
            {
                'value': 'bt',
                'label': 'Bhutan'
            },
            {
                'value': 'io',
                'label': 'British Indian Ocean Territory'
            },
            {
                'value': 'bn',
                'label': 'Brunei Darussalam'
            },
            {
                'value': 'kh',
                'label': 'Cambodia'
            },
            {
                'value': 'cn',
                'label': 'China'
            },
            {
                'value': 'cx',
                'label': 'Christmas Island'
            },
            {
                'value': 'cc',
                'label': 'Cocos (Keeling) Islands'
            },
            {
                'value': 'ge',
                'label': 'Georgia'
            },
            {
                'value': 'hk',
                'label': 'Hong Kong'
            },
            {
                'value': 'in',
                'label': 'India'
            },
            {
                'value': 'id',
                'label': 'Indonesia'
            },
            {
                'value': 'ir',
                'label': 'Iran, Islamic Republic of'
            },
            {
                'value': 'iq',
                'label': 'Iraq'
            },
            {
                'value': 'il',
                'label': 'Israel'
            },
            {
                'value': 'jp',
                'label': 'Japan'
            },
            {
                'value': 'jo',
                'label': 'Jordan'
            },
            {
                'value': 'kz',
                'label': 'Kazakhstan'
            },
            {
                'value': 'kp',
                'label': 'Korea, Democratic People\'s Republic of'
            },
            {
                'value': 'kr',
                'label': 'Korea, Republic of'
            },
            {
                'value': 'kw',
                'label': 'Kuwait'
            },
            {
                'value': 'kg',
                'label': 'Kyrgyzstan'
            },
            {
                'value': 'la',
                'label': 'Lao People\'s Democratic Republic'
            },
            {
                'value': 'lb',
                'label': 'Lebanon'
            },
            {
                'value': 'mo',
                'label': 'Macao'
            },
            {
                'value': 'my',
                'label': 'Malaysia'
            },
            {
                'value': 'mv',
                'label': 'Maldives'
            },
            {
                'value': 'mn',
                'label': 'Mongolia'
            },
            {
                'value': 'mm',
                'label': 'Myanmar'
            },
            {
                'value': 'np',
                'label': 'Nepal'
            },
            {
                'value': 'om',
                'label': 'Oman'
            },
            {
                'value': 'pk',
                'label': 'Pakistan'
            },
            {
                'value': 'ps',
                'label': 'Palestinian Territory, Occupied'
            },
            {
                'value': 'ph',
                'label': 'Philippines'
            },
            {
                'value': 'qa',
                'label': 'Qatar'
            },
            {
                'value': 'sa',
                'label': 'Saudi Arabia'
            },
            {
                'value': 'sg',
                'label': 'Singapore'
            },
            {
                'value': 'lk',
                'label': 'Sri Lanka'
            },
            {
                'value': 'sy',
                'label': 'Syrian Arab Republic'
            },
            {
                'value': 'tw',
                'label': 'Taiwan'
            },
            {
                'value': 'tj',
                'label': 'Tajikistan'
            },
            {
                'value': 'th',
                'label': 'Thailand'
            },
            {
                'value': 'tr',
                'label': 'Turkey'
            },
            {
                'value': 'tm',
                'label': 'Turkmenistan'
            },
            {
                'value': 'ae',
                'label': 'United Arab Emirates'
            },
            {
                'value': 'uz',
                'label': 'Uzbekistan'
            },
            {
                'value': 'vn',
                'label': 'Vietnam'
            },
            {
                'value': 'ye',
                'label': 'Yemen'
            }
        ]
    },
    {
        'label': 'Europe',
        'options': [
            {
                'value': 'al',
                'label': 'Albania'
            },
            {
                'value': 'ad',
                'label': 'Andorra'
            },
            {
                'value': 'at',
                'label': 'Austria'
            },
            {
                'value': 'by',
                'label': 'Belarus'
            },
            {
                'value': 'be',
                'label': 'Belgium'
            },
            {
                'value': 'ba',
                'label': 'Bosnia and Herzegovina'
            },
            {
                'value': 'bg',
                'label': 'Bulgaria'
            },
            {
                'value': 'hr',
                'label': 'Croatia'
            },
            {
                'value': 'cy',
                'label': 'Cyprus'
            },
            {
                'value': 'cz',
                'label': 'Czech Republic'
            },
            {
                'value': 'dk',
                'label': 'Denmark'
            },
            {
                'value': 'ee',
                'label': 'Estonia'
            },
            {
                'value': 'fo',
                'label': 'Faroe Islands'
            },
            {
                'value': 'fi',
                'label': 'Finland'
            },
            {
                'value': 'fr',
                'label': 'France'
            },
            {
                'value': 'de',
                'label': 'Germany'
            },
            {
                'value': 'gi',
                'label': 'Gibraltar'
            },
            {
                'value': 'gr',
                'label': 'Greece'
            },
            {
                'value': 'gg',
                'label': 'Guernsey'
            },
            {
                'value': 'va',
                'label': 'Holy See (Vatican City State)'
            },
            {
                'value': 'hu',
                'label': 'Hungary'
            },
            {
                'value': 'is',
                'label': 'Iceland'
            },
            {
                'value': 'ie',
                'label': 'Ireland'
            },
            {
                'value': 'im',
                'label': 'Isle of Man'
            },
            {
                'value': 'it',
                'label': 'Italy'
            },
            {
                'value': 'je',
                'label': 'Jersey'
            },
            {
                'value': 'lv',
                'label': 'Latvia'
            },
            {
                'value': 'li',
                'label': 'Liechtenstein'
            },
            {
                'value': 'lt',
                'label': 'Lithuania'
            },
            {
                'value': 'lu',
                'label': 'Luxembourg'
            },
            {
                'value': 'mk',
                'label': 'Macedonia, the former Yugoslav Republic of'
            },
            {
                'value': 'mt',
                'label': 'Malta'
            },
            {
                'value': 'md',
                'label': 'Moldova, Republic of'
            },
            {
                'value': 'mc',
                'label': 'Monaco'
            },
            {
                'value': 'me',
                'label': 'Montenegro'
            },
            {
                'value': 'nl',
                'label': 'Netherlands'
            },
            {
                'value': 'no',
                'label': 'Norway'
            },
            {
                'value': 'pl',
                'label': 'Poland'
            },
            {
                'value': 'pt',
                'label': 'Portugal'
            },
            {
                'value': 'ro',
                'label': 'Romania'
            },
            {
                'value': 'ru',
                'label': 'Russian Federation'
            },
            {
                'value': 'sm',
                'label': 'San Marino'
            },
            {
                'value': 'rs',
                'label': 'Serbia'
            },
            {
                'value': 'sk',
                'label': 'Slovakia'
            },
            {
                'value': 'si',
                'label': 'Slovenia'
            },
            {
                'value': 'es',
                'label': 'Spain'
            },
            {
                'value': 'sj',
                'label': 'Svalbard and Jan Mayen'
            },
            {
                'value': 'se',
                'label': 'Sweden'
            },
            {
                'value': 'ch',
                'label': 'Switzerland'
            },
            {
                'value': 'ua',
                'label': 'Ukraine'
            },
            {
                'value': 'gb',
                'label': 'United Kingdom'
            }
        ]
    },
    {
        'label': 'Africa',
        'options': [
            {
                'value': 'dz',
                'label': 'Algeria'
            },
            {
                'value': 'ao',
                'label': 'Angola'
            },
            {
                'value': 'bj',
                'label': 'Benin'
            },
            {
                'value': 'bw',
                'label': 'Botswana'
            },
            {
                'value': 'bf',
                'label': 'Burkina Faso'
            },
            {
                'value': 'bi',
                'label': 'Burundi'
            },
            {
                'value': 'cm',
                'label': 'Cameroon'
            },
            {
                'value': 'cv',
                'label': 'Cape Verde'
            },
            {
                'value': 'cf',
                'label': 'Central African Republic'
            },
            {
                'value': 'td',
                'label': 'Chad'
            },
            {
                'value': 'km',
                'label': 'Comoros'
            },
            {
                'value': 'cg',
                'label': 'Congo'
            },
            {
                'value': 'cd',
                'label': 'Congo, the Democratic Republic of the'
            },
            {
                'value': 'ci',
                'label': 'Cote d\'Ivoire'
            },
            {
                'value': 'dj',
                'label': 'Djibouti'
            },
            {
                'value': 'eg',
                'label': 'Egypt'
            },
            {
                'value': 'gq',
                'label': 'Equatorial Guinea'
            },
            {
                'value': 'er',
                'label': 'Eritrea'
            },
            {
                'value': 'et',
                'label': 'Ethiopia'
            },
            {
                'value': 'ga',
                'label': 'Gabon'
            },
            {
                'value': 'gm',
                'label': 'Gambia'
            },
            {
                'value': 'gh',
                'label': 'Ghana'
            },
            {
                'value': 'gn',
                'label': 'Guinea'
            },
            {
                'value': 'gw',
                'label': 'Guinea-Bissau'
            },
            {
                'value': 'ke',
                'label': 'Kenya'
            },
            {
                'value': 'ls',
                'label': 'Lesotho'
            },
            {
                'value': 'lr',
                'label': 'Liberia'
            },
            {
                'value': 'ly',
                'label': 'Libyan Arab Jamahiriya'
            },
            {
                'value': 'mg',
                'label': 'Madagascar'
            },
            {
                'value': 'mw',
                'label': 'Malawi'
            },
            {
                'value': 'ml',
                'label': 'Mali'
            },
            {
                'value': 'mr',
                'label': 'Mauritania'
            },
            {
                'value': 'mu',
                'label': 'Mauritius'
            },
            {
                'value': 'yt',
                'label': 'Mayotte'
            },
            {
                'value': 'ma',
                'label': 'Morocco'
            },
            {
                'value': 'mz',
                'label': 'Mozambique'
            },
            {
                'value': 'na',
                'label': 'Namibia'
            },
            {
                'value': 'ne',
                'label': 'Niger'
            },
            {
                'value': 'ng',
                'label': 'Nigeria'
            },
            {
                'value': 're',
                'label': 'Reunion'
            },
            {
                'value': 'rw',
                'label': 'Rwanda'
            },
            {
                'value': 'sh',
                'label': 'Saint Helena, Ascension and Tristan da Cunha'
            },
            {
                'value': 'st',
                'label': 'Sao Tome and Principe'
            },
            {
                'value': 'sn',
                'label': 'Senegal'
            },
            {
                'value': 'sc',
                'label': 'Seychelles'
            },
            {
                'value': 'sl',
                'label': 'Sierra Leone'
            },
            {
                'value': 'so',
                'label': 'Somalia'
            },
            {
                'value': 'za',
                'label': 'South Africa'
            },
            {
                'value': 'sd',
                'label': 'Sudan'
            },
            {
                'value': 'ss',
                'label': 'South Sudan'
            },
            {
                'value': 'sz',
                'label': 'Swaziland'
            },
            {
                'value': 'tz',
                'label': 'Tanzania, United Republic of'
            },
            {
                'value': 'tg',
                'label': 'Togo'
            },
            {
                'value': 'tn',
                'label': 'Tunisia'
            },
            {
                'value': 'ug',
                'label': 'Uganda'
            },
            {
                'value': 'eh',
                'label': 'Western Sahara'
            },
            {
                'value': 'zm',
                'label': 'Zambia'
            },
            {
                'value': 'zw',
                'label': 'Zimbabwe'
            }
        ]
    },
    {
        'label': 'Oceania',
        'options': [
            {
                'value': 'as',
                'label': 'American Samoa'
            },
            {
                'value': 'au',
                'label': 'Australia'
            },
            {
                'value': 'ck',
                'label': 'Cook Islands'
            },
            {
                'value': 'fj',
                'label': 'Fiji'
            },
            {
                'value': 'pf',
                'label': 'French Polynesia'
            },
            {
                'value': 'gu',
                'label': 'Guam'
            },
            {
                'value': 'ki',
                'label': 'Kiribati'
            },
            {
                'value': 'mh',
                'label': 'Marshall Islands'
            },
            {
                'value': 'fm',
                'label': 'Micronesia, Federated States of'
            },
            {
                'value': 'nr',
                'label': 'Nauru'
            },
            {
                'value': 'nc',
                'label': 'New Caledonia'
            },
            {
                'value': 'nz',
                'label': 'New Zealand'
            },
            {
                'value': 'nu',
                'label': 'Niue'
            },
            {
                'value': 'nf',
                'label': 'Norfolk Island'
            },
            {
                'value': 'mp',
                'label': 'Northern Mariana Islands'
            },
            {
                'value': 'pw',
                'label': 'Palau'
            },
            {
                'value': 'pg',
                'label': 'Papua New Guinea'
            },
            {
                'value': 'pn',
                'label': 'Pitcairn'
            },
            {
                'value': 'ws',
                'label': 'Samoa'
            },
            {
                'value': 'sb',
                'label': 'Solomon Islands'
            },
            {
                'value': 'tl',
                'label': 'Timor-Leste'
            },
            {
                'value': 'tk',
                'label': 'Tokelau'
            },
            {
                'value': 'to',
                'label': 'Tonga'
            },
            {
                'value': 'tv',
                'label': 'Tuvalu'
            },
            {
                'value': 'um',
                'label': 'United States Minor Outlying Islands'
            },
            {
                'value': 'vu',
                'label': 'Vanuatu'
            },
            {
                'value': 'wf',
                'label': 'Wallis and Futuna'
            }
        ]
    },
    {
        'label': 'North America',
        'options': [
            {
                'value': 'ai',
                'label': 'Anguilla'
            },
            {
                'value': 'ag',
                'label': 'Antigua and Barbuda'
            },
            {
                'value': 'aw',
                'label': 'Aruba'
            },
            {
                'value': 'bs',
                'label': 'Bahamas'
            },
            {
                'value': 'bb',
                'label': 'Barbados'
            },
            {
                'value': 'bz',
                'label': 'Belize'
            },
            {
                'value': 'bm',
                'label': 'Bermuda'
            },
            {
                'value': 'ca',
                'label': 'Canada'
            },
            {
                'value': 'ky',
                'label': 'Cayman Islands'
            },
            {
                'value': 'cr',
                'label': 'Costa Rica'
            },
            {
                'value': 'cu',
                'label': 'Cuba'
            },
            {
                'value': 'dm',
                'label': 'Dominica'
            },
            {
                'value': 'do',
                'label': 'Dominican Republic'
            },
            {
                'value': 'sv',
                'label': 'El Salvador'
            },
            {
                'value': 'gl',
                'label': 'Greenland'
            },
            {
                'value': 'gd',
                'label': 'Grenada'
            },
            {
                'value': 'gp',
                'label': 'Guadeloupe'
            },
            {
                'value': 'gt',
                'label': 'Guatemala'
            },
            {
                'value': 'ht',
                'label': 'Haiti'
            },
            {
                'value': 'hn',
                'label': 'Honduras'
            },
            {
                'value': 'jm',
                'label': 'Jamaica'
            },
            {
                'value': 'mq',
                'label': 'Martinique'
            },
            {
                'value': 'mx',
                'label': 'Mexico'
            },
            {
                'value': 'ms',
                'label': 'Montserrat'
            },
            {
                'value': 'ni',
                'label': 'Nicaragua'
            },
            {
                'value': 'pa',
                'label': 'Panama'
            },
            {
                'value': 'pr',
                'label': 'Puerto Rico'
            },
            {
                'value': 'kn',
                'label': 'Saint Kitts and Nevis'
            },
            {
                'value': 'lc',
                'label': 'Saint Lucia'
            },
            {
                'value': 'pm',
                'label': 'Saint Pierre and Miquelon'
            },
            {
                'value': 'vc',
                'label': 'Saint Vincent and the Grenadines'
            },
            {
                'value': 'tt',
                'label': 'Trinidad and Tobago'
            },
            {
                'value': 'tc',
                'label': 'Turks and Caicos Islands'
            },
            {
                'value': 'us',
                'label': 'United States'
            },
            {
                'value': 'vg',
                'label': 'Virgin Islands, British'
            },
            {
                'value': 'vi',
                'label': 'Virgin Islands, U.S.'
            }
        ]
    },
    {
        'label': 'Antarctica',
        'options': [
            {
                'value': 'aq',
                'label': 'Antarctica'
            },
            {
                'value': 'bv',
                'label': 'Bouvet Island'
            },
            {
                'value': 'tf',
                'label': 'French Southern Territories'
            },
            {
                'value': 'hm',
                'label': 'Heard Island and McDonald Islands'
            },
            {
                'value': 'gs',
                'label': 'South Georgia and the South Sandwich Islands'
            }
        ]
    },
    {
        'label': 'South America',
        'options': [
            {
                'value': 'ar',
                'label': 'Argentina'
            },
            {
                'value': 'bo',
                'label': 'Bolivia'
            },
            {
                'value': 'br',
                'label': 'Brazil'
            },
            {
                'value': 'cl',
                'label': 'Chile'
            },
            {
                'value': 'co',
                'label': 'Colombia'
            },
            {
                'value': 'ec',
                'label': 'Ecuador'
            },
            {
                'value': 'fk',
                'label': 'Falkland Islands (Malvinas)'
            },
            {
                'value': 'gf',
                'label': 'French Guiana'
            },
            {
                'value': 'gy',
                'label': 'Guyana'
            },
            {
                'value': 'py',
                'label': 'Paraguay'
            },
            {
                'value': 'pe',
                'label': 'Peru'
            },
            {
                'value': 'sr',
                'label': 'Suriname'
            },
            {
                'value': 'uy',
                'label': 'Uruguay'
            },
            {
                'value': 've',
                'label': 'Venezuela'
            }
        ]
    }
];

