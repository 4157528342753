// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default (data: any, filename: string, mime?: string): any => {
    const blob = new Blob([data], {
        type: mime || 'application/octet-stream'
    });

    if (typeof (window.navigator as any).msSaveBlob !== 'undefined') {
        (window.navigator as any).msSaveBlob(blob, filename);
    } else {
        const blobURL = window.URL.createObjectURL(blob);
        const tempLink = document.createElement('a');

        tempLink.style.display = 'none';
        tempLink.href = blobURL;
        tempLink.setAttribute('download', filename);

        if (typeof tempLink.download === 'undefined') {
            tempLink.setAttribute('target', '_blank');
        }

        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(blobURL);
    }
};