import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import { forEach, omit } from 'lodash';
import { RootState } from 'app/store';
import Table from 'app/components/Table/Table';
import { Filter } from 'app/components/Table/tableInterfaces';
import Skeleton from 'app/components/Skeleton/Skeleton';
import Tooltip from 'components/Tooltip/Tooltip';
import Button from 'components/Button/Button';
import Modal from 'components/Modal/Modal';
import { ReactComponent as EditIcon } from 'static/icons/edit.svg';
import { ReactComponent as DownloadIcon } from 'static/icons/download.svg';
import { ReactComponent as CopyIcon } from 'static/icons/duplicate.svg';
import { ReactComponent as RefreshIcon } from 'static/icons/refresh.svg';
import { campaignShortcodesTableHeaders, downloadFormatActions } from 'pages/CampaignDetails/constants/campaignShortcodesTableConstants';
import { CampaignShortcodesTableEntry } from 'pages/CampaignDetails/interfaces/campaignShortcodes';
import { getShortcodesTableDataSortedPaged, getShortcodesTableTotalRows } from 'pages/CampaignDetails/campaignDetailsSelectors';
import { showNotification } from 'state/ui/notifications/notificationsSlice';
import {
  downloadShortcodeFunction,
  getShortcodes,
  setShortcodeDownload,
  setShortcodeFieldValue,
  setShortcodeId
} from 'state/campaign/campaignSlice';
import {
  resetShortcodesTableFilters,
  setShortcodesRowsPerPage,
  setShortcodesTableFilters,
  setShortcodesTablePage,
  setShortcodesTableSort,
} from 'state/ui/campaignDetails/campaignDetailsPageSlice';
import { SHORTCODES_ROWS_NUMBERS } from 'app/constants/shortcodesConstants';
import styles from './CampaignShortcodesTable.module.scss';

export default function CampaignShortcodesTable(): React.ReactElement {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const isCampaignLoading = useSelector<RootState, boolean>((state) => state.campaign.isCampaignLoading);
  const isShortcodesLoading = useSelector<RootState, boolean>((state) => state.campaign.isShortcodesLoading);

  const campaignId = useSelector<RootState, string>((state) => state.campaign.campaign.campaign_id);
  const partners = useSelector<RootState, any[]>((state) => (state.campaign.campaign || []).partners);
  const shortcodes = useSelector<RootState, CampaignShortcodesTableEntry[]>(getShortcodesTableDataSortedPaged);

  const shortcodeId = useSelector<RootState, string | null>((state) => state.campaign.shortcodeId);
  const shortcodesTableTotalRows = useSelector<RootState, number>(getShortcodesTableTotalRows);
  const rowsPerPage = useSelector<RootState, number>((state) => state.campaignDetailsPage.shortcodesRowsPerPage);
  const shortcodesTablePage = useSelector<RootState, any>((state) => state.campaignDetailsPage.shortcodesTablePage);
  const shortcodesTableSort = useSelector<RootState, any>((state) => state.campaignDetailsPage.shortcodesTableSort);
  const shortcodesTableFilters = useSelector<RootState, { [key: string]: Filter }>(
    (state) => state.campaignDetailsPage.shortcodesTableFilters
  );

  useEffect(() => {
    dispatch(getShortcodes(campaignId));
  }, [campaignId]);

  useEffect(() => {
    return () => {
      dispatch(setShortcodeId(''))
    }
  }, []);


  const refreshTable = useCallback(() => {
    dispatch(getShortcodes(campaignId));
  }, [campaignId]);

  const clearFilters = () => {
    dispatch(resetShortcodesTableFilters());
  };

  const shortcodeDownload = (format: string) => {
    dispatch(setShortcodeDownload({ format }));
    const findCurrentShortcodeURL = shortcodes?.find((item: any) => item.short_code_id === shortcodeId)?.short_code_url;
    
    dispatch(downloadShortcodeFunction(findCurrentShortcodeURL));
  };

  const rows = shortcodes?.map(
    ({
      short_code,
      short_code_url,
      redirect_url,
      ignore_campaign_filter,
      notes,
      short_code_id
    }) => [
        <EditIcon className={styles.editIcon} onClick={() => handleEdit(short_code_id)} />,
        short_code,
        <div className={styles.shortCodeIdCol}>
          <div className={styles.textWidth}>{short_code_url}</div>
          <div className={styles.actions}>
            <button
              className={styles.copyButton}
              onClick={() => {
                navigator?.clipboard?.writeText(short_code_url);
                dispatch(showNotification('Shortcode URL Copied!'));
              }}>
              <CopyIcon className={styles.copyIcon} />
            </button>

            <Popover
              isOpen={isPopoverOpen && shortcodeId === short_code_id}
              positions={['bottom']}
              align='start'
              padding={8}
              onClickOutside={() => {
                setIsPopoverOpen(false);
                dispatch(setShortcodeId(''))
              }}
              content={({ position, childRect, popoverRect }) => (
                <ArrowContainer
                  position={position}
                  childRect={childRect}
                  popoverRect={popoverRect}
                  arrowColor={'#fff'}
                  arrowSize={8}
                >
                  <div className={styles.popover}>
                    <p className={styles.downloadText}>Download asset</p>
                    {downloadFormatActions.map((item, index) => (
                      <button
                        key={index}
                        className={styles.formatButton}
                        onClick={() => shortcodeDownload(item.value)}
                      >
                        <span>{item.name}</span>
                      </button>
                    ))}
                  </div>
                </ArrowContainer>
              )}
            >
              <button
                className={styles.copyButton}
                onClick={() => {
                  setIsPopoverOpen(true);
                  dispatch(setShortcodeId(short_code_id))
                }}
              >
                <DownloadIcon className={styles.downloadIcon} />
              </button>
            </Popover>
          </div>
        </div>,
        redirect_url,
        <div>{ignore_campaign_filter.toString()}</div>,
        notes,
        <div className={styles.shortCodeIdCol}>
          <div className={styles.textWidth}>{short_code_id}</div>
          <div>
            <button
              className={styles.copyButton}
              onClick={() => {
                navigator?.clipboard?.writeText(short_code_id);
                dispatch(showNotification('Shortcode ID Copied!'));
              }}>
              <CopyIcon className={styles.copyIcon} />
            </button>
          </div>
        </div>,
      ]
  );

  const handleEdit = (shortcodeId: string) => {
    const findShortcode = shortcodes.find(item => item.short_code_id === shortcodeId);
    const newShortcode = omit(findShortcode, 'short_code_id');
    setModal(!modal);
    dispatch(setShortcodeId(shortcodeId));

    forEach(newShortcode, (value: string | boolean, key) => {
      dispatch(
        setShortcodeFieldValue({
          field: key,
          value,
        })
      )
    });
  }

  const isLoading = isCampaignLoading && isShortcodesLoading;

  return (
    <div className={styles.root}>
      <div className={styles.controlsHolder}>
        <Button onClick={clearFilters} disabled={isShortcodesLoading}>
          Clear Filters
        </Button>
        <Button
          onClick={refreshTable}
          iconRight={<RefreshIcon height={16} />}
          disabled={isShortcodesLoading}
        >
          Refresh Table
        </Button>
      </div>

      <Skeleton isLoading={isLoading}>
        {!isLoading && (
          <>
            <Table
              sort={shortcodesTableSort}
              filters={shortcodesTableFilters}
              page={shortcodesTablePage}
              onRowsNumberSelected={(value: number) => dispatch(setShortcodesRowsPerPage(value))}
              rowsPerPage={rowsPerPage}
              rowsNumbers={SHORTCODES_ROWS_NUMBERS}
              rows={rows}
              totalRows={shortcodesTableTotalRows}
              headers={campaignShortcodesTableHeaders}
              onPageSelected={(p: number) => dispatch(setShortcodesTablePage(p))}
              onSort={(field: any) => dispatch(setShortcodesTableSort(field))}
              onFilter={(filter) => dispatch(setShortcodesTableFilters(filter))}
            />

            {partners.length > 0 && (
              <div className={styles.roundButtonHolder}>
                <button className={styles.roundButton} onClick={() => setModal(!modal)}>
                  <Tooltip position="left" tooltip="Add Shortcode">
                    <span>+</span>
                  </Tooltip>
                </button>
              </div>
            )}

            {modal && (
              <Modal
                formAction={true}
                modalTitle={shortcodeId ? 'Edit Shortcode' : 'Add Shortcode'}
                closeModal={() => setModal(!modal)}
              />
            )}
          </>
        )}
      </Skeleton>
    </div>
  );
}
