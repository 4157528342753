import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { difference, findIndex, intersection, toString, union } from 'lodash';
import moment from 'moment-timezone';
import parseISO from 'date-fns/parseISO';
import { Formik } from 'formik';
import { RootState } from 'app/store';
import { flatCountries, groupedCountries } from 'app/constants/countries';
import Datepicker from 'components/Datepicker/Datepicker';
import Dropdown from 'components/Dropdown/Dropdown';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import Select, { components } from 'react-select';
import Skeleton from '../../../../app/components/Skeleton/Skeleton';
import { DEFAULT_TIME, DEFAULT_TIMEZONE, OFFSETS } from 'constants/rewardConstants';
import { ReactComponent as CheckIcon } from '../../../../static/icons/checkmark.svg';
import { ReactComponent as CrossIcon } from '../../../../static/icons/cross-small.svg';
import { ReactComponent as AngleDown } from '../../../../static/icons/angle-small-down.svg';
import {
    sendRewardData,
    setIsRewardCreated,
    setRewardConditionsFieldValue
} from '../../../../state/campaign/campaignSlice';
import styles from './RewardConditions.module.scss';


type CountriesArray = string[];

export default function RewardConditions(): React.ReactElement {
    const history = useHistory();
    const dispatch = useDispatch();
    
    const values = useSelector<RootState,
        { target_variant: string; daily_limit: string; target_countries: string[]; start_at: string | null; end_at: string | null }>((state) => state.campaign.rewardsConditions.values);
    const rewardsValues = useSelector<RootState,
        { reward_name: string }>((state) => state.campaign.rewardsSettings.values);

    const campaign = useSelector<RootState, any | null>((state) => state.campaign.campaign);
    const selectedTimezone = campaign?.properties?.view_timezone ? campaign?.properties?.view_timezone : DEFAULT_TIMEZONE;
    const [startZone, setStartZone] = useState(selectedTimezone);
    const [endZone, setEndZone] = useState(selectedTimezone);

    const [startTime, setStartTime] = useState(values.start_at ? moment(values.start_at).utcOffset(startZone).format('HH:mm') : DEFAULT_TIME);
    const [endTime, setEndTime] = useState(values.end_at ? moment(values.end_at).utcOffset(endZone).format('HH:mm') : DEFAULT_TIME);
    const [startDate, setStartDate] = useState(values.start_at ? parseISO(values.start_at) : null);
    const [endDate, setEndDate] = useState(values.end_at ? parseISO(values.end_at) : null);
    
    const [selectedCountriesList, setSelectedCountriesList] = useState<CountriesArray>([]);
    const [countrySearched, setCountrySearched] = useState<string>('');
    const [expandCountryGroup, setExpandCountryGroup] = useState(99);
    const isRewardCreated = useSelector<RootState, boolean>((state) => state.campaign.isRewardCreated);
    const rewardId = useSelector<RootState, string | null>((state) => state.campaign.rewardId);
    const isCampaignLoading = useSelector<RootState, boolean>((state) => state.campaign.isCampaignLoading) || false;

    const dispatchValueChange = (name: string, value: string | string[] | null) => {
        return dispatch(
            setRewardConditionsFieldValue({
                field: name,
                value,
            })
        );
    }

    const parseDate = (value: string | null, time: string, zone: string) => {
        time = time === '' ? DEFAULT_TIME : time;
        return moment(moment(value).format('yyyy-MM-DD') + 'T' + time + zone).toISOString()
    }

    const onSubmit = () => {
        dispatchValueChange('start_at', parseDate(toString(startDate), startTime, startZone))
        dispatchValueChange('end_at', parseDate(toString(endDate), endTime, endZone));
        dispatch(sendRewardData());
    }

    const handleSelectedCountries = (countries: string[]) => {
        setSelectedCountriesList(countries);
        dispatchValueChange('target_countries', countries);
    }

    const handleCountriesSearch = (inputValue: string) => {
        setCountrySearched(inputValue);
    }

    const Option = (props: any) => {
        const parentIndex = findIndex(props.options, (item: any) => {
            const itemOptions = item.options.map((itemOption: any) => itemOption.value)
            return itemOptions.includes(props.data.value);
        });

        if (expandCountryGroup === parentIndex || countrySearched.length > 0) {
            return (
                <components.Option {...props}>
                    <label className={styles.selectGroupOptionItem}>
                        <input type="checkbox"
                            defaultChecked={props.isSelected}
                        />
                        <span>{props.label}</span>
                    </label>
                </components.Option>
            );
        } else {
            return null
        }
    };

    const MultiValueContainer = (props: any) => {
        return (
            <components.MultiValueContainer  {...props}>
                <div className={styles.selectCountriesPlaceholder}>
                    {values.target_countries && values.target_countries.length > 0 && (
                        <p>{values.target_countries.length} Target {values.target_countries.length > 1 ? 'Countries' : 'Country'} Selected</p>
                    )}
                </div>
            </components.MultiValueContainer>
        );
    };

    useEffect(() => {
        if (values && values.target_countries) {
            setSelectedCountriesList(values.target_countries)
        }

        if (isRewardCreated) {
            dispatch(setIsRewardCreated(false));
            const pathArray = location.pathname.split('/');

            if (rewardId) {
                if (pathArray[pathArray.length - 3] === 'edit') {
                    const pathArray = location.pathname.split('/');
                    pathArray[pathArray.length - 2] = rewardId;
                    history.push(pathArray.join('/'));
                } else {
                    history.push(`./edit/${rewardId}/editRewards`);
                }
            }
        }
    })

    return (
        <Skeleton isLoading={isCampaignLoading}>
            <Formik
                initialValues={values}
                onSubmit={onSubmit}
            >
                {({ errors, setFieldValue, setTouched, handleChange, handleSubmit, touched }) => (
                    <form className={styles.root} onSubmit={handleSubmit}>
                        <div className={styles.formInputContainer}>
                            <label className={styles.inputLabel}>
                                Reward Name
                            </label>
                            <div className={styles.textInput}>
                                {rewardsValues.reward_name}
                            </div>
                        </div>

                        <div className={styles.formGroupContainer}>
                            <label className={styles.inputLabel}>
                                Starts on
                            </label>

                            <div className={styles.formGroupWrapper}>
                                <div className={styles.formInputContainer}>
                                    <div className={styles.inputHolder}>
                                        <Datepicker
                                            name="start_at"
                                            value={startDate}
                                            maxDate={endDate}
                                            disabled={isCampaignLoading}
                                            onChange={(value: Date) => setStartDate(value)}
                                        />
                                        <CrossIcon
                                            className={styles.deleteInputValue}
                                            onClick={() => setStartDate(null)}
                                        />
                                    </div>
                                </div>
                                <div className={styles.formInputContainer}>
                                    <div className={styles.inputHolder}>
                                        <div className='react-datepicker-wrapper'>
                                            <input
                                                type="time"
                                                onChange={(e) => setStartTime(e.target.value === '' ? DEFAULT_TIME : e.target.value)}
                                                value={startTime}
                                                name="start_at_time"
                                                disabled={isCampaignLoading}
                                                required />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.formInputContainer}>
                                    <div className={styles.inputHolder}>
                                        {campaign?.properties?.view_timezone ? (
                                            <p className={styles.timezoneStyle}>{`UTC ${selectedTimezone}`}</p>
                                        ) : (
                                            <Dropdown
                                                options={OFFSETS.map((offset) => ({ value: offset, label: 'UTC' + offset }))}
                                                className={styles.formInput}
                                                value={{
                                                    label: 'UTC' + startZone,
                                                    value: startZone
                                                }}
                                                name="startOffset"
                                                disabled={isCampaignLoading}
                                                onChange={({ value }) => setStartZone(value)}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.formGroupContainer}>
                            <label className={styles.inputLabel}>
                                Ends
                            </label>

                            <div className={styles.formGroupWrapper}>
                                <div className={styles.formInputContainer}>
                                    <div className={styles.inputHolder}>
                                        <Datepicker
                                            name="end_at"
                                            minDate={startDate}
                                            value={endDate}
                                            disabled={isCampaignLoading}
                                            onChange={(value: Date) => setEndDate(value)}
                                        />
                                        <CrossIcon
                                            className={styles.deleteInputValue}
                                            onClick={() => setEndDate(null)}
                                        />
                                    </div>
                                </div>
                                <div className={styles.formInputContainer}>
                                    <div className={styles.inputHolder}>
                                        <div className='react-datepicker-wrapper'>
                                            <input
                                                type="time"
                                                disabled={isCampaignLoading}
                                                onChange={(e) => setEndTime(e.target.value === '' ? DEFAULT_TIME : e.target.value)}
                                                value={endTime}
                                                name="end_at_time"
                                                required />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.formInputContainer}>
                                    <div className={styles.inputHolder}>
                                        {campaign?.properties?.view_timezone ? (
                                            <p className={styles.timezoneStyle}>{`UTC ${selectedTimezone}`}</p>
                                        ) : (
                                            <Dropdown
                                                options={OFFSETS.map((offset) => ({ value: offset, label: 'UTC' + offset }))}
                                                className={styles.formInput}
                                                value={{
                                                    label: 'UTC' + endZone,
                                                    value: endZone
                                                }}
                                                name="endOffset"
                                                disabled={isCampaignLoading}
                                                onChange={({ value }) => setEndZone(value)}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.formInputContainer}>
                            <label className={styles.inputLabel}>Daily Limit</label>
                            <div className={styles.inputHolder}>
                                <Input
                                    type="number"
                                    className={styles.formInput}
                                    value={values.daily_limit || ''}
                                    name="daily_limit"
                                    disabled={isCampaignLoading}
                                    onChange={(e) => {
                                        handleChange(e);
                                        dispatchValueChange('daily_limit', e.target.value);
                                    }}
                                    error={touched.daily_limit ? (errors.daily_limit as string) : null}
                                />
                            </div>
                        </div>

                        <div className={styles.formInputContainer}>
                            <label className={styles.inputLabel}>Variant</label>
                            <div className={styles.inputHolder}>
                                <Input
                                    className={styles.formInput}
                                    value={values.target_variant || ''}
                                    name="target_variant"
                                    disabled={isCampaignLoading}
                                    onChange={(e) => {
                                        handleChange(e);
                                        dispatchValueChange('target_variant', e.target.value);
                                    }}
                                    error={touched.target_variant ? (errors.target_variant as string) : null}
                                />
                            </div>
                        </div>

                        <div className={styles.formInputContainer}>
                            <label className={styles.inputLabel}>Countries</label>

                            <Select
                                className={styles.selectGroupWrapper}
                                isMulti={true}
                                isDisabled={isCampaignLoading}
                                placeholder={'Select Target Country'}
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                value={(values.target_countries || []).map((v) => {
                                    const { value, label } = flatCountries.find((c) => c.value === v) || {
                                        value: '',
                                        label: ''
                                    };
                                    return { value, label };
                                })}
                                onChange={(options: any) => {
                                    const values = options.map((option: any) => option.value);
                                    setTouched({ ...touched, target_countries: true }, true);
                                    setFieldValue('target_countries', values);
                                    handleSelectedCountries(values);
                                }}
                                onInputChange={handleCountriesSearch}
                                options={groupedCountries.map((group, index) => {
                                    const groupCountries = group.options.map(country => country.value);
                                    const selectedCountries = intersection(groupCountries, values.target_countries);

                                    return ({
                                        ...group,
                                        label: (
                                            <div className={countrySearched.length ? styles.selectGroupWrap : ''}>
                                                {countrySearched.length < 1 && (
                                                    <div className={styles.selectGroupHead}>
                                                        <input type="checkbox"
                                                            checked={selectedCountries.length > 0}
                                                            onChange={() => {
                                                                if (selectedCountries.length > 0) {
                                                                    handleSelectedCountries(difference(selectedCountriesList, groupCountries))
                                                                } else {
                                                                    handleSelectedCountries(union(selectedCountriesList, groupCountries))
                                                                }
                                                            }}
                                                        />
                                                        <span>
                                                            {group.label}
                                                        </span>
                                                        <div className={styles.selectGroupHeadAction}
                                                            onClick={() => {
                                                                setExpandCountryGroup(expandCountryGroup === index ? 99 : index);
                                                            }}>
                                                            <span className={styles.selectGroupOptionItemCounter}>
                                                                {selectedCountries.length} Target {selectedCountries.length > 1 ? 'Countries' : 'Country'} Selected
                                                            </span>
                                                            <i className={expandCountryGroup === index ? styles.selectGroupHeadActionActive : styles.selectGroupHeadActionDefault}>
                                                                <AngleDown height={20}></AngleDown>
                                                            </i>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        ),
                                        options: (group.options.map((option) => ({
                                            ...option
                                        })
                                        ))
                                    })
                                })}
                                components={{ Option, MultiValueContainer }}
                            />
                        </div>

                        <div>
                            <Button submit type="action" size="large"
                                className={styles.submitButton}
                                disabled={isCampaignLoading}
                                iconLeft={<CheckIcon height={20}></CheckIcon>}>
                                Confirm Changes
                            </Button>
                        </div>
                    </form>
                )}
            </Formik>
        </Skeleton>
    );
}
